import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import {jwtDecode} from "jwt-decode";

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const pmaId = decodedToken.id;

  const [hacs, setHacs] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/hacs?pmaId=${pmaId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setHacs(response.data);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    };

    fetchData();
  }, [token]);

  const openMenu = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  return (
    <>
      {hacs.length !== 0 ? (
        <div>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="user-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('common-name')}</TableCell>
                <TableCell align="left">{t('common-last-name')}</TableCell>
                <TableCell align="center">{t('common-phone')}</TableCell>
                <TableCell align="center">{t('common-status')}</TableCell>
                <TableCell align="center">{t('common-last-update')}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hacs.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.last_name}</TableCell>
                  <TableCell align="center">
                    {row.prefix_phone} {row.phone}
                  </TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{row.last_update}</TableCell>
                  <TableCell align="right">
                    <span
                      aria-controls={
                        anchorEl[row.id] ? `menu${index}` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        Boolean(anchorEl[row.id]) ? 'true' : undefined
                      }
                      onClick={openMenu(row.id)}
                    >
                      ...
                    </span>
                    <Menu
                      className="submenu"
                      id={`${row.id}`}
                      anchorEl={anchorEl[row.id]}
                      open={Boolean(anchorEl[row.id])}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(`/pma/personal-details/${row.id}`)
                        }
                      >
                        {t('pma-hac-tab-option1')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate(`/pma/vehicles/${row.id}`)}
                      >
                        {t('pma-hac-tab-option2')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate(`/pma/preferences/${row.id}`)}
                      >
                        {t('pma-hac-tab-option3')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate(`/pma/retailer-info/${row.id}`)}
                      >
                        {t('pma-hac-tab-option4')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate(`/pma/pma-info/${row.id}`)}
                      >
                        {t('pma-hac-tab-option5')}
                      </MenuItem>
                      <MenuItem
                        onClick={
                          row.status !== 'exited'
                            ? () => navigate(`/pma/unenroll/${row.id}`)
                            : null
                        }
                        className={row.status === 'exited' ? 'disabled' : ''}
                      >
                        {t('pma-hac-tab-option6')}
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="no-result">
          <h2 className="txt-center">
            {t('pma-hac-tab-title-empty')}
            <small>{t('pma-hac-tab-text-empty')}</small>
          </h2>
        </div>
      )}
    </>
  );
}

export default TableHac;
