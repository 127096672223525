import './preferences.scss';
import React, { useState, useEffect } from 'react';
import {Link, Navigate, useLocation, useParams} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from "../../../../utils/decode-token";
import { useTranslation } from "react-i18next";
import PmaPreferencesHac from "../../../organisms/role-pma/hac-preferences/preferences-hac";

const HacListPmaPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  return (
    <>
      {
        (token && role === 'pma') ? (
          <div className="App">
            <Header />
            <main>
              <section>
                <div className="inner">
                  <Link to="/pma/main" className="back-button"> <span className="icon-chevron-left"></span>  {t('common-back')}</Link>
                  <h4>{t('pma-preferences-title')}</h4>
                  <main className="preferences">
                    <PmaPreferencesHac />
                  </main>
                </div>
              </section>
            </main>
          </div>
        ) : (
          <Navigate to="/login" />
        )
      }
    </>
  );
}

export default HacListPmaPreferences;
