import "./modal-confirmation.scss";
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { Box } from "@mui/material";

function ModalConfirmation({
  open,
  handleClose,
  formRef,
  text,
  action,
  setLoadMsg,
}) {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(Object.fromEntries(new FormData(formRef.current).entries()));
  }, [formRef]);

  const { value } = formData;

  const handleConfirm = () => {
    action(value, setLoadMsg, handleClose);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="box-confirmation">
        <div className="box-content">
          <h2 id="modal-modal-title">Are you sure?</h2>
          <p id="modal-modal-description">{text}</p>
          <p id="hac-email">{value}</p>
          <div className="modal-buttons">
            <Button
              variant="contained"
              className="btn button-mod cancel-mod"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn button-mod confirm-mod"
              type="submit"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalConfirmation;
