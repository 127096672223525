import './main.scss';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import TableHac from '../../../organisms/role-pma/table-hac/table-hac';
import {useTranslation} from "react-i18next";

function MainPma() {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;



  return (
    <>
      {token && role === 'pma' ? (
        <div className="App">
          <Header />
          <main>
            <section>
              <div className="inner">
                <div className="table-wrapper">
                  <TableHac />
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default MainPma;
