import './welcome.scss';
import Header from '../../organisms/header/header.js';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import apiClient from '../../../utils/apiClient';

function Welcome() {
  const { token } = useParams();
  const { t, i18n } = useTranslation();

  const [isFirstCheckboxChecked, setIsFirstCheckboxChecked] = useState(false);
  const [isSecondCheckboxChecked, setIsSecondCheckboxChecked] = useState(false);

  const handleFirstCheckboxChange = (event) => {
    setIsFirstCheckboxChecked(event.target.checked);
  };

  const handleSecondCheckboxChange = (event) => {
    setIsSecondCheckboxChecked(event.target.checked);
  };

  useEffect(() => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/hac/basic-info`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    apiClient
      .get(url, config)
      .then((response) => {
        const userLanguage = response.data.preferredLanguage;
        i18n.changeLanguage(userLanguage.toLowerCase());
      })
      .catch((error) => {
        console.error('Error al realizar la solicitud:', error);
      });
  }, [token, i18n]);

  const activateAccount = () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/hac/activate`;

    const payload = {
      consents: isSecondCheckboxChecked,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    apiClient
      .patch(url, payload, config)
      .then((response) => {
        window.location.href = response.data.link;
      })
      .catch((error) => {
        console.error('Error al realizar la solicitud Patch:', error);
      });
  };

  return (
    <div className="App">
      <Header />
      <main>
        <section className="welcome">
          <div className="inner">
            <h2>{t('welcome-title')}</h2>
            <p>{t('welcome-text1')}</p>
            <ul>
              <li>
                <span className="icon-user"></span> <p>{t('welcome-item1')}</p>
              </li>
              <li>
                <span className="icon-car-shield"></span>{' '}
                <p>{t('welcome-item2')}</p>
              </li>
              <li>
                <span className="icon-tools"></span> <p>{t('welcome-item3')}</p>
              </li>
              <li>
                <span className="icon-edit"></span> <p>{t('welcome-item4')}</p>
              </li>
              <li>
                <span className="icon-pin"></span> <p>{t('welcome-item5')}</p>
              </li>
            </ul>
            <p>{t('welcome-text2')}</p>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox onChange={handleFirstCheckboxChange} />}
                label={t('welcome-check1')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox onChange={handleSecondCheckboxChange} />}
                label={t('welcome-check2')}
              />
            </div>
            <div className="actions">
              <button
                className="btn"
                disabled={!isFirstCheckboxChecked}
                onClick={activateAccount}
              >
                {t('welcome-action')}
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Welcome;
