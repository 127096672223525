import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';
import ModalNominateOnBehalf from '../modal-nominate-on-behalf/modal-nominate-on-behalf';

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();

  const [hacs, setHacs] = useState([]);
  const [filteredHacs, setFilteredHacs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [markets] = useState([
    { name: 'au-cz-sk', countries: ['AT', 'CZ', 'SK'] },
    { name: 'benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'france', countries: ['FR'] },
    { name: 'germany', countries: ['DE'] },
    { name: 'iberia', countries: ['ES', 'PT'] },
    { name: 'italy', countries: ['IT'] },
    { name: 'switzerland', countries: ['CH'] },
    { name: 'turkey', countries: ['TR'] },
  ]);

  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    status: '',
  });
  const [anchorEl, setAnchorEl] = useState({});
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura de la modal

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/hacs`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setHacs(response.data);
          setFilteredHacs(response.data);
          onDataLoaded();

          const uniqueCountries = [
            ...new Set(response.data.map((hac) => hac.area_code)),
          ];
          setCountries(uniqueCountries);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      if (selectedMarket) {
        setCountries(selectedMarket.countries);
      } else {
        const allCountries = [...new Set(hacs.map((hac) => hac.area_code))];
        setCountries(allCountries);
      }

      setFilters({
        ...filters,
        market: value,
        country: '', // Resetea el filtro de país
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...hacs];
    if (filters.name) {
      filtered = filtered.filter(
        (hac) =>
          hac.name.toLowerCase().includes(filters.name.toLowerCase()) ||
          hac.last_name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }
    if (filters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === filters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((hac) =>
          selectedMarket.countries.includes(hac.area_code)
        );
      }
    }
    if (filters.country) {
      filtered = filtered.filter((hac) => hac.area_code === filters.country);
    }
    if (filters.status) {
      filtered = filtered.filter((hac) => hac.status === filters.status);
    }
    setFilteredHacs(filtered);
    console.log(filteredHacs);
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <header>
        <h2>{t('admin-main-tab1-title')}</h2>
        {role !== 'admin' && (
          <button className="btn secondary" onClick={handleOpenModal}>
            + {t('admin-main-tab1-action')}
          </button>
        )}
      </header>
      <ModalNominateOnBehalf open={openModal} onClose={handleCloseModal} />
      {hacs.length !== 0 ? (
        <>
          <div className="filters">
            <p>{t('common-search')}</p>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} md={3}>
                <div className="form-control">
                  <label htmlFor="name">
                    {t('common-name')}/{t('common-last-name')}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                  />
                </div>
              </Grid>
              {(role === 'admin' || role === 'marketAdmin') && (
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="market">{t('common-market')}</label>
                    <select
                      name="market"
                      id="market"
                      className="normal"
                      value={filters.market}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-market')}</option>
                      {markets.map((market) => (
                        <option key={market.name} value={market.name}>
                          {t(`market-${market.name}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              )}
              {(role === 'admin' || role === 'marketAdmin') && (
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="country">{t('common-country')}</label>
                    <select
                      name="country"
                      id="country"
                      className="normal"
                      value={filters.country}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-country')}</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {t(`countries-${country}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              )}
              <Grid xs={12} sm={6} md={2}>
                <div className="form-control">
                  <label htmlFor="status">{t('common-status')}</label>
                  <select
                    name="status"
                    id="status"
                    className="normal"
                    value={filters.status}
                    onChange={handleFilterChange}
                  >
                    <option value="">{t('common-all-status')}</option>
                    <option value="nominated">{t('common-nominated')}</option>
                    <option value="active">{t('common-active')}</option>
                    <option value="exited">{t('common-exited')}</option>
                  </select>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={2}>
                <div className="actions">
                  <button className="btn" onClick={applyFilters}>
                    {t('common-apply')}
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="summary">
            <strong>
              {t('common-total-results')}: <span>{filteredHacs.length}</span>
            </strong>{' '}
            |
            <span>
              {t('common-users-active')}:{' '}
              <span>
                {filteredHacs.filter((hac) => hac.status === 'active').length}
              </span>
            </span>{' '}
            |
            <span>
              {t('common-users-nominated')}:{' '}
              <span>
                {
                  filteredHacs.filter((hac) => hac.status === 'nominated')
                    .length
                }
              </span>
            </span>
          </div>
          <div className="table-wrapper">
            {filteredHacs.length !== 0 ? (
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="user-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('common-name')}</TableCell>
                    <TableCell align="left">{t('common-last-name')}</TableCell>
                    <TableCell align="center">{t('common-pma')}</TableCell>
                    <TableCell align="center">{t('common-retailer')}</TableCell>
                    <TableCell align="center">{t('common-market')}</TableCell>
                    <TableCell align="center">{t('common-country')}</TableCell>
                    <TableCell align="center">{t('common-status')}</TableCell>
                    <TableCell align="center">
                      {t('common-last-update')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredHacs.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.last_name}</TableCell>
                      <TableCell align="center">
                        {row.first_pma_email}{' '}
                      </TableCell>
                      <TableCell align="center">{row.retailer}</TableCell>
                      <TableCell align="center">
                        {t(`market-${row.market}`)}
                      </TableCell>
                      <TableCell align="center">
                        {t(`countries-${row.area_code}`)}
                      </TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.last_update}</TableCell>
                      <TableCell align="right">
                        <span
                          aria-controls={
                            anchorEl[row.id] ? `menu${index}` : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            Boolean(anchorEl[row.id]) ? 'true' : undefined
                          }
                          onClick={handleClick(row.id)}
                        >
                          ...
                        </span>
                        <Menu
                          className="submenu"
                          id={`${row.id}`}
                          anchorEl={anchorEl[row.id]}
                          open={Boolean(anchorEl[row.id])}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {/*
                          <MenuItem onClick={() => {}}>
                            {t('admin-main-tab1-option1')}
                          </MenuItem>
                          <MenuItem onClick={() => {}}>
                            {t('admin-main-tab1-option2')}
                          </MenuItem>
                          <MenuItem onClick={() => {}}>
                            {t('admin-main-tab1-option3')}
                          </MenuItem>
                          */}
                          <MenuItem
                            onClick={() =>
                              navigate(`/admin/nominating-retailer/${row.id}`)
                            }
                          >
                            {t('admin-main-tab1-option4')}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(`/admin/assigned-pmas/${row.id}`)
                            }
                          >
                            {t('admin-main-tab1-option5')}
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="no-result">
                <h2 className="txt-center">{t('admin-main-title-empty')}</h2>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="no-result">
          <h2 className="txt-center">
            {t('admin-main-tab1-title-empty')}
            <small>{t('admin-main-tab1-text-empty')}</small>
          </h2>
        </div>
      )}
    </>
  );
}

export default TableHac;
