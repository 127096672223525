import './preferences.scss';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from "../../../../utils/decode-token";
import { Link } from "react-router-dom";
import Preferences from "../../../organisms/role-hac/hac-preferences/preferences-hac";
import Wadsworth from "../../../organisms/role-hac/wadsworth/wadsworth";
import { useTranslation } from "react-i18next";

const HacPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cat = params.get('cat');
  const categories = location.state?.categories || [];

  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    if (cat) {
      const index = categories.findIndex((item) => item.id === cat);
      if (index !== -1) {
        setActiveItem(index);
      }
    }
  }, [cat, categories]);

  const handleItemClick = (index) => {
    setActiveItem(index);
    // TODO: Cargar contenido de la categoría
  };

  return (
    <>
      {
        (token && role === 'hac') ? (
          <div className="App">
            <Header />
            <main>
              <Wadsworth />
              <section>
                <div className="inner menu">
                  <aside className="lateral-menu">
                    <h2>{t('hac-menu-preferences-title')}</h2>
                    <ul>
                      {Array.isArray(categories) && categories.map((item, index) => (
                        <li
                          key={item.id}
                          className={activeItem === index ? 'on' : ''}
                          onClick={() => handleItemClick(index)}
                        >
                          <Link to={`/hac/preferences?cat=${item.id}`} state={{categories}}>{t(`hac-preferences-${item.id}`)}</Link>
                        </li>
                      ))}
                    </ul>
                  </aside>
                  <main className="preferences">
                    {/*TODO: pedir los textos para cada una de las categorias y traducirlos*/}
                    <h6>{t(`hac-preferences-${cat}`)}</h6>
                    <p>{t(`hac-preferences-${cat}-text`)}</p>
                    <Preferences cat={cat} />
                  </main>
                </div>
              </section>
            </main>
          </div>
        ) : (
          <Navigate to="/login" />
        )
      }
    </>
  );
}

export default HacPreferences;
