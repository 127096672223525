import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { Alert } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalHacConfirmDeletePreferences({
  open,
  handleClose,
  preferenceId,
  onDelete,
}) {
  const { t } = useTranslation();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDeletePreference = async () => {
    try {
      const token = sessionStorage.getItem('token') || null;
      const url = `${process.env.REACT_APP_API_URL}/hac/preference/${preferenceId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };

      await apiClient.delete(url, config);

      handleClose();
      setOpenSnackbar(true);
      onDelete(); // Llama al callback onDelete después de borrar
    } catch (error) {
      console.error('Error al eliminar la preferencia:', error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Box sx={style}>
          <h2>{t('hac-preferences-delete-title')}</h2>
          <p>{t('hac-preferences-delete-text')}</p>
          <div className="actions">
            <button
              className="btn secondary"
              onClick={() => {
                handleClose();
              }}
            >
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={handleDeletePreference}>
              {t('common-remove')}
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('hac-preferences-delete-feedback')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalHacConfirmDeletePreferences;
