import './main.scss';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from "../../../../utils/decode-token";
import TableHac from "../../../organisms/role-admin/table-hac/table-hac";
import TablePma from "../../../organisms/role-admin/table-pma/table-pma";
import TableRetailer from "../../../organisms/role-admin/table-retailer/table-retailer";
import { useTranslation } from "react-i18next";

const MainAdmin = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('hac');
  const [reloadHac, setReloadHac] = useState(true);
  const [reloadPma, setReloadPma] = useState(true);
  const [reloadRetailer, setReloadRetailer] = useState(true);

  useEffect(() => {
    if (location.pathname.includes('/hac')) {
      setActiveTab('hac');
      setReloadHac(true);
    } else if (location.pathname.includes('/pma')) {
      setActiveTab('pma');
      setReloadPma(true);
    } else if (location.pathname.includes('/retailer')) {
      setActiveTab('retailer');
      setReloadRetailer(true);
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'hac') {
      setReloadHac(true);
      setReloadPma(false);
      setReloadRetailer(false);
      navigate('/admin/main/hac');
    } else if (tab === 'pma') {
      setReloadHac(false);
      setReloadPma(true);
      setReloadRetailer(false);
      navigate('/admin/main/pma');
    } else {
      setReloadHac(false);
      setReloadPma(false);
      setReloadRetailer(true);
      navigate('/admin/main/retailer');
    }
  };

  const handleDataLoaded = () => {
    if (activeTab === 'hac') setReloadHac(false);
    if (activeTab === 'pma') setReloadPma(false);
    if (activeTab === 'retailer') setReloadRetailer(false);
  };

  return (
    <>
      {
        (token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin')) ? (
        //(token && role === 'nscChampion') ? (
          <div className="App">
            <Header />
            <main>
              <div className="tabs">
                <ul>
                  <li className={activeTab === 'hac' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('hac')}>{t('admin-main-tabs-tab1')}</span>
                  </li>
                  <li className={activeTab === 'pma' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('pma')}>{t('admin-main-tabs-tab2')}</span>
                  </li>
                  <li className={activeTab === 'retailer' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('retailer')}>{t('admin-main-tabs-tab3')}</span>
                  </li>
                </ul>
              </div>
              {activeTab === 'hac' && (
                <section className={`tab-content ${activeTab === 'hac' ? 'active' : ''}`}>
                  <div className="inner">

                    <TableHac reload={reloadHac} onDataLoaded={handleDataLoaded} />

                  </div>
                </section>
              )}
              {activeTab === 'pma' && (
                <section className={`tab-content ${activeTab === 'pma' ? 'active' : ''}`}>
                  <div className="inner">
                    <header>
                      <h2>{t('admin-main-tab2-title')}</h2>
                      {/*<button className="btn secondary">+ {t('admin-main-tab2-action')}</button>*/}
                    </header>
                    <TablePma reload={reloadPma} onDataLoaded={handleDataLoaded} />
                  </div>
                </section>
              )}
              {activeTab === 'retailer' && (
                <section className={`tab-content ${activeTab === 'retailer' ? 'active' : ''}`}>
                  <div className="inner">
                    <header>
                      <h2>{t('admin-main-tab3-title')}</h2>
                      {/*<button className="btn secondary">+ {t('admin-main-tab3-action')}</button>*/}
                    </header>
                    <TableRetailer reload={reloadRetailer} onDataLoaded={handleDataLoaded} />
                  </div>
                </section>
              )}
            </main>
          </div>
        ) : (
          <Navigate to="/login" />
        )}
    </>
  );
};

export default MainAdmin;
