import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { useTranslation } from "react-i18next";
import apiClient from '../../../../utils/apiClient';
import {Alert} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalPmaConfirmDeleteVehicle({
  open,
  handleClose,
  vehicleId,
  fetchData,
  hacId,
}) {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDeleteVehicle = () => {
    apiClient
      .delete(
        `${process.env.REACT_APP_API_URL}/pma/hac-vehicle/${vehicleId}?hacId=${hacId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`,
          },
        }
      )
      .then((response) => {
        console.log('Datos borrados exitosamente:', response.data);
        setOpenSnackbar(true);
        handleClose(); // Cerrar el modal después de borrar los datos
        fetchData(); // Volver a obtener los datos
      })
      .catch((error) => {
        console.error('Error al borrar los datos:', error);
      });
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <h2>{t('hac-vehicle-delete-title')}</h2>
          <p>{t('hac-vehicle-delete-text')}</p>
          <div className="actions">
            <button className="btn secondary" onClick={handleClose}>
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={handleDeleteVehicle}>
              {t('common-remove')}
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}>
        <Alert
          severity="success"
          sx={{ width: '100%' }}
        >{t('hac-vehicle-delete-feedback')}</Alert>
      </Snackbar>
    </>
  );
}

export default ModalPmaConfirmDeleteVehicle;
