import './login.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/img/logo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import decodeToken from '../../../utils/decode-token';
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import { Alert } from '@mui/material';

const Login = () => {
  sessionStorage.clear();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useParams();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (logout) {
      setSnackbarMessage(t('login-logout')); // Configura el mensaje traducido aquí
      setSnackbarOpen(true);
    }
  }, [logout, t]);

  const onSubmitHandler = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
    let email = document.getElementById('email').value;
    let password = document.getElementById('password').value;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email: email,
          password: password,
        }
      );
      if (response.data.token && response.data.refresh_token) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('refresh_token', response.data.refresh_token);
      }
      let role = decodeToken(response.data.token).role;
      switch (role) {
        case 'admin':
        case 'marketAdmin':
        case 'countryAdmin':
          navigate('/admin/main');
          break;
        case 'superAdmin':
          navigate('/super-admin/main');
          break;
        case 'countryManager':
          navigate('/country-manager/main');
          break;
        case 'retailer':
          navigate('/retailer/main');
          break;
        case 'hac':
          navigate('/hac/main');
          break;
        case 'pma':
          navigate('/pma/main');
          break;
        case 'shippingAdmin':
          navigate('/shipping-admin/main');
          break;
        default:
          break;
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error('Error de autenticación:', errorCode, errorMessage);
      const inputs = document.querySelectorAll('input');
      inputs.forEach((input) => {
        input.classList.add('error');
      });
      document.querySelector('.error-msg').classList.remove('hidden');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="App">
      <main className="login">
        <div className="inner">
          <img src={Logo} alt="Range Rover Colective" className="logo" />
          <form onSubmit={onSubmitHandler}>
            <div className="form-control">
              <label htmlFor="email">{t('common-user')}</label>
              <input type="text" id="email" autoComplete="off" maxLength="50" />
            </div>
            <div className="form-control">
              <label htmlFor="password">{t('common-key')}</label>
              <input
                type="password"
                id="password"
                autoComplete="off"
                maxLength="50"
              />
            </div>
            <p className="error-msg hidden">{t('login-error')}</p>
            <button className="btn" type="submit">
              {t('login-action')}
            </button>
          </form>
          <p>
            <Link to="/password-recovery">{t('login-password-recovery')}</Link>
          </p>
        </div>
      </main>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
