import './modal-reassign-pma.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from "react-i18next";
import apiClient from "../../../../utils/apiClient";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalReassignPma({ open, onClose, hacId, currentPma, pmaType }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token") || null;

  const [allPma, setAllPma] = useState([]);
  const [filteredPmas, setFilteredPmas] = useState([]);
  const [selectedPma, setSelectedPma] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchPma = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/pmas`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setAllPma(response.data);
          if (currentPma) {
            const filtered = response.data.filter(sp => sp.id !== currentPma.id);
            setFilteredPmas(filtered);
          } else {
            setFilteredPmas(response.data);
          }
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    };


      fetchPma();

  }, [currentPma, token]);

  const handlePmaChange = (event) => {
    const selectedId = event.target.value;
    const selectedPerson = (currentPma ? filteredPmas : allPma).find(sp => sp.id === selectedId);
    setSelectedPma(selectedPerson);
  };

  const handleSave = async () => {
    if (selectedPma) {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/pma-hac/${hacId}`;

      const payload = {
        pma_id: selectedPma.id,
        pma_type: pmaType
      };

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await apiClient.post(url, payload, config);
        if (response.status === 200 || response.status === 201) {
          setSelectedPma(null);
          onClose();
          setSnackbarMessage(t('reasign-pma-feedback-ok'));
          setSnackbarOpen(true);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    }
  };

  const handleClose = () => {
    setSelectedPma(null);
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const pmaToDisplay = currentPma ? filteredPmas : allPma;

  return (
    <>
      <Modal open={open} onClose={handleClose} className="reasign-saleperson">
        <Box sx={style}>
          <h2>{t(`reasign-pma${pmaType}-title`)}</h2>

          <div className="actual">
            <p>{t(`reasign-pma${pmaType}-subtitle`)}</p>
            <p><strong>{currentPma ? `${currentPma.name} ${currentPma.last_name}` : 'N/A'}</strong></p>
          </div>

          <div className="form-control">
            <label htmlFor="name">
              {t("reasign-pma-label")}
            </label>
            <select className="normal" onChange={handlePmaChange}>
              <option value="">{t("reasign-pma-select-new-pma")}</option>
              {pmaToDisplay.map(person => (
                <option key={person.id} value={person.id}>{person.name} {person.last_name}</option>
              ))}
            </select>
          </div>

          {selectedPma && (
            <div className="info">
              <dl>
                <div>
                  <dt>{t('common-name')}</dt>
                  <dd>{selectedPma.name} {selectedPma.last_name}</dd>
                </div>
                <div>
                  <dt>{t('common-email')}</dt>
                  <dd>{selectedPma.email}</dd>
                </div>
                <div>
                  <dt>{t('common-phone')}</dt>
                  <dd>{selectedPma.prefix_phone} {selectedPma.phone}</dd>
                </div>
                <div>
                  <dt>{t('common-country')}</dt>
                  <dd>{t(`countries-${selectedPma.area_code}`)}</dd>
                </div>
              </dl>
            </div>
          )}

          <div className="actions">
            <button className="btn secondary" onClick={handleClose}>{t('common-cancel')}</button>
            <button className="btn" onClick={handleSave}>{t('common-save')}</button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}>
        <Alert severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalReassignPma;
