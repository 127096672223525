import React  from 'react';
import './settings.scss';
import Header from '../../../organisms/header/header.js';
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

function SuperAdminSettings() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);

  return (
    <div className="App">
      <Header />
      <main>
        <section className="settings">
          <div className="inner">
            <h2>{t('settings-title')}</h2>
            <p>{t('settings-text')}</p>

          </div>
        </section>
      </main>
    </div>
  );
}

export default SuperAdminSettings;
