import './personal-details-hac.scss';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Header from '../../header/header.js';
import decodeToken from '../../../../utils/decode-token.js';
import Grid from '@mui/material/Unstable_Grid2';
import ModalDataSaved from '../../modal-data-saved/modal-data-saved.js';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import Input from '../../../atoms/input.js';
import Snackbar from '@mui/material/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { Alert } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const setValues = (values, setterFn) => {
  const requiredValues = [
    'name',
    'email',
    'middle_name',
    'last_name',
    'phone',
    'preferred_address',
    'prefix_phone',
    'title',
    'birth_day',
    'birth_day_partner',
    'pma_notes',
    'name_partner',
    'last_name_partner',
  ];
  for (const [key, value] of Object.entries(values)) {
    if (requiredValues.includes(key) && value) {
      setterFn(key, value);
    }
  }
};

function PmaPersonalDetailsHac() {
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { hacId } = useParams();

  const [countries, setCountries] = useState([]);
  const [profileStatus, setProfileStatus] = useState(null);
  const [phoneError, setPhoneError] = useState('');

  const {
    formState,
    register,
    handleSubmit,
    setValue,
    watch,
    resetField,
    control,
  } = useForm();

  const [preferredAddress, setPreferredAddress] = useState('');

  const privateAddress = watch('private_address');
  const companyAddress = watch('company_address');

  const { errors, isSubmitting } = formState;

  // rellenar el select de pais con los paises disponibles
  useEffect(() => {
    apiClient
      .get(`${process.env.REACT_APP_API_URL}/hac/countries`)
      .then(({ data }) => setCountries(data))
      .catch(console.error);
  }, []);

  useState(() => {
    const fetchPreferredAddress = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/hac?hacId=${hacId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const response = await apiClient.get(url, config);
        console.log(
          'Fetched preferred address:',
          response.data.preferred_address
        );
        setPreferredAddress(response.data.preferred_address);
      } catch (error) {
        console.error('Error fetching preferred address:', error);
      }
    };

    fetchPreferredAddress();
  }, []);

  useEffect(() => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/pma/hac?hacId=${hacId}`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    apiClient
      .get(url, config)
      .then((response) => {
        console.log('llama aqui');
        setProfileStatus(response.data.status);
        console.log(profileStatus);
        if (response.status !== 200)
          throw new Error("It couldn't retry hac profile");
        setProfileStatus(response.data.status);
        setValues(response.data, setValue);

        // Set initial values for privateCountry and companyCountry
        const privateAddr = response.data.address.find(
          (address) => address.address_type === 'private'
        );
        const companyAddr = response.data.address.find(
          (address) => address.address_type === 'company'
        );
        if (companyAddr) {
          setValue('company_address', companyAddr);
        }
        if (privateAddr) {
          setValue('private_address', privateAddr);
        }
      })
      .catch((error) => {
        console.error('Error al realizar la solicitud:', error);
      });
    // eslint-disable-next-line
  }, []);

  const handlePreferredAddressChange = (event) => {
    console.log('Changed preferred address to:', event.target.value);
    setPreferredAddress(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleOpenModalDataSaved = () => setOpen(true);
  const handleCloseModalDataSaved = () => setOpen(false);
  const handleAceptModalDataSaved = () => {
    setOpen(false);
    // Realizar la navegación a la página anterior
    navigate(-1); // Esto navegará hacia atrás en la historia
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClearAddress = async (type) => {
    const address = type === 'private' ? privateAddress : companyAddress;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
      params: {
        hacId: address.hac,
      },
    };

    try {
      await apiClient.delete(
        `${process.env.REACT_APP_API_URL}/pma/hac-address/${address.id}`,
        config
      );
      Object.keys(address).forEach((key) => {
        resetField(`${type}_address.${key}`);
      });
      setSnackbarMessage('Address cleared and deleted from the database');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(
        error.response?.status === 409
          ? "Can't delete a preferred address"
          : error.message
      );
      setSnackbarOpen(true);
    }
  };

  const onSubmit = handleSubmit(
    async ({
      private_address,
      company_address,
      middle_name,
      birth_day,
      birth_day_partner,
      name_partner,
      last_name_partner,
      ...rest
    }) => {
      if (Object.keys(errors).length) return;
      let payload = {
        ...rest,
        middle_name: middle_name.length ? middle_name : undefined,
        name_partner: name_partner.length ? name_partner : undefined,
        last_name_partner: last_name_partner.length
          ? last_name_partner
          : undefined,
        birth_day: birth_day ? birth_day : undefined,
        birth_day_partner: birth_day_partner ? birth_day_partner : undefined,
        preferred_address: preferredAddress ? preferredAddress : undefined,
        address: [],
      };

      if (private_address.alias) {
        payload.address.push({
          id: private_address.id,
          alias: private_address.alias,
          area_code: private_address.area_code,
          address_type: 'private',
          address_line_1: private_address.address_line_1,
          address_number: private_address.address_number,
          city: private_address.city,
          postal_code: private_address.postal_code,
          address_line_2: private_address.address_line_2
            ? private_address.address_line_2
            : undefined,
        });
      }

      if (company_address.company_name) {
        payload.address.push({
          id: company_address.id,
          company_name: company_address.company_name,
          area_code: company_address.area_code,
          address_type: 'company',
          address_line_1: company_address.address_line_1,
          address_number: company_address.address_number,
          city: company_address.city,
          postal_code: company_address.postal_code,
          address_line_2: company_address.address_line_2
            ? company_address.address_line_2
            : undefined,
        });
      }

      if (payload.address.length === 0) {
        delete payload.address;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };
      try {
        const fullPhoneNumber = parsePhoneNumberFromString(
          payload.prefix_phone + payload.phone
        );

        if (!fullPhoneNumber || !fullPhoneNumber.isValid()) {
          setPhoneError('Invalid phone number format');
          return;
        } else {
          setPhoneError('');

          await apiClient.put(
            `${process.env.REACT_APP_API_URL}/pma/hac/${hacId}`,
            JSON.parse(JSON.stringify(payload)),
            config
          );
          handleOpenModalDataSaved();
        }
      } catch (error) {
        console.error('Error al enviar la invitación:', error);
      }
    }
  );

  const validateAddressField = (field, allFields) => {
    const privateAddress = allFields.private_address;
    const isPrivateAddressSelected = preferredAddress === 'private';

    const isAnyFieldFilled = Object.values(privateAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isPrivateAddressSelected) {
      if (!privateAddress[field]) {
        return 'This field is required if any address field is filled';
      }
    }
    return true;
  };

  const validateCompanyField = (field, allFields) => {
    const companyAddress = allFields.company_address;
    const isCompanyAddressSelected = preferredAddress === 'company';

    const isAnyFieldFilled = Object.values(companyAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isCompanyAddressSelected) {
      if (!companyAddress[field]) {
        return 'This field is required if any address field is filled';
      }
    }
    return true;
  };

  return (
    <>
      {token && role === 'pma' ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={onSubmit}>
                  <Link to="/pma/main" className="back-button">
                    <span className="icon-chevron-left"></span>
                    {t('common-back')}
                  </Link>
                  <h2>{t('pma-profile-title')}</h2>
                  <Grid container spacing={3}>
                    <Grid xs={6} sm={4} md={2}>
                      <div className="form-control">
                        <label htmlFor="title">{t('common-title')}</label>
                        <select
                          name="title"
                          id="title"
                          className="normal"
                          disabled={profileStatus === 'exited'}
                          {...register('title')}
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Mx">Mx</option>
                        </select>
                      </div>
                    </Grid>
                    <Grid xs={12} sm={8} md={5}>
                      <div className="form-control required">
                        <Input
                          type="text"
                          id="name"
                          label={t('common-name')}
                          maxLength="50"
                          error={errors?.name}
                          disabled={profileStatus === 'exited'}
                          {...register('name', {
                            validate: (v) => {
                              const { error } = Joi.string()
                                .required()
                                .error(() => new Error("Name can't be empty"))
                                .validate(v);
                              return error?.message ? error.message : true;
                            },
                          })}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={12} sm={4} md={4}>
                      <div className="form-control">
                        <label htmlFor="middle_name">
                          {t('common-middle-name')}
                        </label>
                        <input
                          maxLength="50"
                          type="text"
                          id="middle_mame"
                          disabled={profileStatus === 'exited'}
                          {...register('middle_name', {})}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={5} md={4}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="last_name"
                          label={t('common-last-name')}
                          error={errors?.last_name}
                          disabled={profileStatus === 'exited'}
                          {...register('last_name', {
                            validate: (v) => {
                              const { error } = Joi.string()
                                .required()
                                .error(
                                  () => new Error("Last Name can't be empty")
                                )
                                .validate(v);
                              return error?.message ? error.message : true;
                            },
                          })}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={3} md={4}>
                      <div className="form-control datepicker">
                        <label htmlFor="birth_day">
                          {t('common-birthday')}
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            name="birth_day"
                            control={control}
                            render={({ field }) => (
                              <DateField
                                {...field}
                                format="DD/MM"
                                disabled={profileStatus === 'exited'}
                                onChange={(date) => {
                                  const isValidDate = dayjs(date).isValid();
                                  if (isValidDate) {
                                    const formattedDate =
                                      dayjs(date).format('MM/DD');
                                    field.onChange(formattedDate);
                                  } else {
                                    field.onChange('');
                                  }
                                }}
                                value={
                                  field.value
                                    ? dayjs(field.value, 'MM/DD')
                                    : null
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={12} sm={6} md={6}>
                      <div className="form-control">
                        <label htmlFor="email">{t('common-email')}</label>
                        <input
                          maxLength="50"
                          type="text"
                          id="email"
                          {...register('email', {
                            validate: (v) =>
                              Joi.string()
                                .email({ tlds: { allow: false } })
                                .required()
                                .validate(v),
                          })}
                          readOnly
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={6} md={6}>
                      <div className="form-control required">
                        <Grid container spacing={1}>
                          <Grid xs={4} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                type="text"
                                id="prefix_phonee"
                                label={t('common-prefix')}
                                disabled={profileStatus === 'exited'}
                                error={errors?.prefix_phone}
                                {...register('prefix_phone', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .regex(/^\+[0-9]{1,3}$/)
                                      .required()
                                      .error(
                                        () =>
                                          new Error(
                                            "Prefix can't be empty or higher than +XXX"
                                          )
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                          <Grid xs={8} sm={8} md={8}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="phone"
                                label={t('common-phone')}
                                disabled={profileStatus === 'exited'}
                                error={errors?.phone}
                                {...register('phone', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .required()
                                      .error(
                                        () => new Error("Phone can't be empty")
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                              {phoneError && (
                                <p className="error-message">{phoneError}</p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {/* Partner information */}
                  {profileStatus === 'active' && (
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={4} md={4}>
                        <div className="form-control">
                          <label htmlFor="name_partner">
                            {t('common-name-partner')}
                          </label>
                          <input
                            maxLength="50"
                            type="text"
                            id="name_partner"
                            disabled={profileStatus === 'exited'}
                            {...register('name_partner', {})}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={5} md={4}>
                        <div className="form-control">
                          <label htmlFor="last_name_partner">
                            {t('common-last-name-partner')}
                          </label>
                          <input
                            maxLength="50"
                            type="text"
                            id="last_name_partner"
                            disabled={profileStatus === 'exited'}
                            {...register('last_name_partner', {})}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={3} md={4}>
                        <div className="form-control datepicker">
                          <label htmlFor="birth_day_partner">
                            {t('common-birthday-partner')}
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name="birth_day_partner"
                              control={control}
                              render={({ field }) => (
                                <DateField
                                  {...field}
                                  format="DD/MM"
                                  disabled={profileStatus === 'exited'}
                                  onChange={(date) => {
                                    const isValidDate = dayjs(date).isValid();
                                    if (isValidDate) {
                                      const formattedDate =
                                        dayjs(date).format('MM/DD');
                                      field.onChange(formattedDate);
                                    } else {
                                      field.onChange('');
                                    }
                                  }}
                                  value={
                                    field.value
                                      ? dayjs(field.value, 'MM/DD')
                                      : null
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={3}>
                    <Grid xs={12} sm={12} md={12}>
                      <h3>{t('pma-profile-personal-notes-title')}</h3>
                      <p>{t('pma-profile-personal-notes-text')}</p>
                      <textarea
                        id="note"
                        maxLength="20000"
                        disabled={profileStatus === 'exited'}
                        {...register('pma_notes')}
                        className="txt-area"
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />

                  {profileStatus === 'active' && (
                    <>
                      <p>{t('retailer-nomination-choose-address')}</p>
                      <div className="form-control">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={preferredAddress}
                          onChange={handlePreferredAddressChange}
                        >
                          <FormControlLabel
                            value="private"
                            name="preferred_address"
                            control={<Radio />}
                            label={t('common-private-address')}
                          />
                          <FormControlLabel
                            value="company"
                            name="preferred_address"
                            control={<Radio />}
                            label={t('common-company-address')}
                          />
                        </RadioGroup>
                      </div>
                    </>
                  )}

                  {profileStatus === 'active' && (
                    <div className="address">
                      <div className="address-header">
                        <h3>{t('common-private-address')}</h3>
                        {profileStatus === 'active' && (
                          <button
                            type="button"
                            className="btn-form btn text"
                            onClick={() => {
                              handleClearAddress('private');
                            }}
                          >
                            <Snackbar
                              open={snackbarOpen}
                              autoHideDuration={6000}
                              onClose={handleSnackbarClose}
                            >
                              <Alert severity="success" sx={{ width: '100%' }}>
                                {snackbarMessage}
                              </Alert>
                            </Snackbar>
                            <span className="icon-delete"></span>
                          </button>
                        )}
                      </div>
                      <div className="form-control">
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="alias_private"
                            placeholder="E.g. 'Second residence' or 'Holiday house'"
                            label={t('common-alias')}
                            error={errors?.private_address?.alias}
                            {...register('private_address.alias', {
                              validate: (value, allValues) =>
                                validateAddressField('alias', allValues),
                            })}
                          />
                        </div>
                      </div>
                      <Grid xs={12} sm={6} md={6}>
                        <div className="form-control">
                          <Grid container spacing={1}>
                            <Grid xs={12} sm={8} md={8}>
                              <div className="form-control">
                                <Input
                                  maxLength="150"
                                  type="text"
                                  id="address_line_1_private"
                                  label={t('common-address-name')}
                                  error={
                                    errors?.private_address?.address_line_1
                                  }
                                  {...register(
                                    'private_address.address_line_1',
                                    {
                                      validate: (value, allValues) =>
                                        validateAddressField(
                                          'address_line_1',
                                          allValues
                                        ),
                                    }
                                  )}
                                />
                              </div>
                            </Grid>
                            <Grid xs={12} sm={4} md={4}>
                              <div className="form-control">
                                <Input
                                  maxLength="5"
                                  type="text"
                                  id="address_number_private"
                                  disabled={profileStatus === 'exited'}
                                  label={t('common-address-name')}
                                  error={
                                    errors?.private_address?.address_number
                                  }
                                  {...register(
                                    'private_address.address_number',
                                    {
                                      validate: (value, allValues) =>
                                        validateAddressField(
                                          'address_number',
                                          allValues
                                        ),
                                    }
                                  )}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <div className="form-control">
                        <div className="form-control">
                          <label htmlFor="address_line_2_private">
                            {t('common-address-line-2')}
                          </label>
                          <input
                            maxLength="150"
                            type="text"
                            disabled={profileStatus === 'exited'}
                            id="address_line_2_private"
                            {...register('private_address.address_line_2')}
                          />
                        </div>
                      </div>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <label htmlFor="country_private">
                              {t('common-country')}
                            </label>
                            <select
                              name="area_code_private"
                              id="country_private"
                              className="normal"
                              disabled={profileStatus === 'exited'}
                              error={errors?.private_address?.area_code}
                              {...register('private_address.area_code', {
                                validate: (value, allValues) =>
                                  validateAddressField('area_code', allValues),
                              })}
                            >
                              <option key="" value="">
                                {t('common-select-country')}
                              </option>
                              {countries.map((country) => (
                                <option
                                  key={country.area_code}
                                  value={country.area_code}
                                >
                                  {t('countries-' + country.area_code)}
                                </option>
                              ))}
                            </select>
                            {errors?.private_address?.area_code && (
                              <span className="input__error">
                                {errors.private_address.area_code.message}
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <Input
                              maxLength="50"
                              type="text"
                              id="city_private"
                              disabled={profileStatus === 'exited'}
                              label={t('common-city')}
                              error={errors?.private_address?.city}
                              {...register('private_address.city', {
                                validate: (value, allValues) =>
                                  validateAddressField('city', allValues),
                              })}
                            />
                          </div>
                        </Grid>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <Input
                              maxLength="50"
                              type="text"
                              id="postal_code_private"
                              label={t('common-zip-cp')}
                              disabled={profileStatus === 'exited'}
                              error={errors?.private_address?.postal_code}
                              {...register('private_address.postal_code', {
                                validate: (value, allValues) =>
                                  validateAddressField(
                                    'postal_code',
                                    allValues
                                  ),
                              })}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {profileStatus === 'active' && (
                    <div className="address">
                      <div className="address-header">
                        <h3>{t('common-company-address')}</h3>
                        {profileStatus === 'active' && (
                          <button
                            type="button"
                            className="btn-form btn text"
                            onClick={() => handleClearAddress('company')}
                          >
                            <Snackbar
                              open={snackbarOpen}
                              autoHideDuration={6000}
                              onClose={handleSnackbarClose}
                            >
                              <Alert severity="success" sx={{ width: '100%' }}>
                                {snackbarMessage}
                              </Alert>
                            </Snackbar>
                            <span className="icon-delete"></span>{' '}
                          </button>
                        )}
                      </div>
                      <div className="form-control">
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="company_name"
                            disabled={profileStatus === 'exited'}
                            label={t('common-company-name')}
                            error={errors?.company_address?.company_name}
                            {...register('company_address.company_name', {
                              validate: (value, allValues) =>
                                validateCompanyField('company_name', allValues),
                            })}
                          />
                        </div>
                      </div>
                      <Grid xs={12} sm={6} md={6}>
                        <div className="form-control">
                          <Grid container spacing={1}>
                            <Grid xs={12} sm={8} md={8}>
                              <div className="form-control">
                                <Input
                                  maxLength="150"
                                  type="text"
                                  id="address_line_1_company"
                                  label={t('common-address-name')}
                                  disabled={profileStatus === 'exited'}
                                  error={
                                    errors?.company_address?.address_line_1
                                  }
                                  {...register(
                                    'company_address.address_line_1',
                                    {
                                      validate: (value, allValues) =>
                                        validateCompanyField(
                                          'address_line_1',
                                          allValues
                                        ),
                                    }
                                  )}
                                />
                              </div>
                            </Grid>
                            <Grid xs={12} sm={4} md={4}>
                              <div className="form-control">
                                <Input
                                  maxLength="5"
                                  type="text"
                                  id="address_number_company"
                                  label={t('common-address-number')}
                                  disabled={profileStatus === 'exited'}
                                  error={
                                    errors?.company_address?.address_number
                                  }
                                  {...register(
                                    'company_address.address_number',
                                    {
                                      validate: (value, allValues) =>
                                        validateCompanyField(
                                          'address_number',
                                          allValues
                                        ),
                                    }
                                  )}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <div className="form-control">
                        <div className="form-control">
                          <label htmlFor="address_line_2_company">
                            {t('common-address-line-2')}
                          </label>
                          <input
                            maxLength="150"
                            type="text"
                            id="address_line_2_company"
                            disabled={profileStatus === 'exited'}
                            {...register('company_address.address_line_2')}
                          />
                        </div>
                      </div>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <label htmlFor="country_company">
                              {t('common-country')}
                            </label>
                            <select
                              name="area_code_company"
                              id="country_company"
                              className="normal"
                              disabled={profileStatus === 'exited'}
                              error={errors?.company_address?.area_code}
                              {...register('company_address.area_code', {
                                validate: (value, allValues) =>
                                  validateCompanyField('area_code', allValues),
                              })}
                            >
                              <option key="" value="">
                                {t('common-select-country')}
                              </option>
                              {countries.map((country) => (
                                <option
                                  key={country.area_code}
                                  value={country.area_code}
                                >
                                  {t('countries-' + country.area_code)}
                                </option>
                              ))}
                            </select>
                            {errors?.company_address?.area_code && (
                              <span className="input__error">
                                {errors.company_address.area_code.message}
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <Input
                              maxLength="50"
                              type="text"
                              id="city_company"
                              label={t('common-city')}
                              disabled={profileStatus === 'exited'}
                              error={errors?.company_address?.city}
                              {...register('company_address.city', {
                                validate: (value, allValues) =>
                                  validateCompanyField('city', allValues),
                              })}
                            />
                          </div>
                        </Grid>
                        <Grid xs={12} sm={4} md={4}>
                          <div className="form-control">
                            <Input
                              maxLength="50"
                              type="text"
                              id="postal_code_company"
                              label={t('common-zip-cp')}
                              disabled={profileStatus === 'exited'}
                              error={errors?.company_address?.postal_code}
                              {...register('company_address.postal_code', {
                                validate: (value, allValues) =>
                                  validateCompanyField(
                                    'postal_code',
                                    allValues
                                  ),
                              })}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {profileStatus !== 'exited' && (
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={6}>
                        <button className="btn" type="submit">
                          {t('common-save')}
                        </button>
                      </Grid>
                    </Grid>
                  )}
                </form>
              </div>
            </section>
          </main>

          <ModalDataSaved
            open={open}
            handleClose={handleCloseModalDataSaved}
            handleAceptModalDataSaved={handleAceptModalDataSaved}
          />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default PmaPersonalDetailsHac;
