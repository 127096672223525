import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import decodeToken from '../../../../utils/decode-token';

function TableRetailer({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [markets] = useState([
    { name: 'au-cz-sk', countries: ['AT', 'CZ', 'SK'] },
    { name: 'benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'france', countries: ['FR'] },
    { name: 'germany', countries: ['DE'] },
    { name: 'iberia', countries: ['ES', 'PT'] },
    { name: 'italy', countries: ['IT'] },
    { name: 'switzerland', countries: ['CH'] },
    { name: 'turkey', countries: ['TR'] },
  ]);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    city: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/retailers`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const data = response.data;
          setRetailers(data);
          setFilteredRetailers(data);
          onDataLoaded();

          const uniqueCountries = [
            ...new Set(data.map((retailer) => retailer.area_code)),
          ];
          const uniqueCities = [
            ...new Set(data.map((retailer) => retailer.city)),
          ];
          setCountries(uniqueCountries);
          setCities(uniqueCities);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      if (selectedMarket) {
        setCountries(selectedMarket.countries);
      } else {
        const allCountries = [
          ...new Set(retailers.map((retailer) => retailer.area_code)),
        ];
        setCountries(allCountries);
      }

      setFilters({
        ...filters,
        market: value,
        country: '',
        city: '',
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...retailers];
    if (filters.name) {
      filtered = filtered.filter((retailer) =>
        retailer.name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }
    if (filters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === filters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((retailer) =>
          selectedMarket.countries.includes(retailer.area_code)
        );
      }
    }
    if (filters.country) {
      filtered = filtered.filter(
        (retailer) => retailer.area_code === filters.country
      );
    }
    if (filters.city) {
      filtered = filtered.filter((retailer) =>
        retailer.city.toLowerCase().includes(filters.city.toLowerCase())
      );
    }
    setFilteredRetailers(filtered);
  };

  return (
    <>
      {retailers.length !== 0 ? (
        <>
          <div className="filters">
            <p>{t('common-search')}</p>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} md={3}>
                <div className="form-control">
                  <label htmlFor="name">{t('common-name')}</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                  />
                </div>
              </Grid>
              {role === 'admin' && (
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="market">{t('common-market')}</label>
                    <select
                      name="market"
                      id="market"
                      className="normal"
                      value={filters.market}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-market')}</option>
                      {markets.map((market) => (
                        <option key={market.name} value={market.name}>
                          {t(`market-${market.name}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              )}
              {(role === 'admin' ||
                role === 'nscChampion' ||
                role === 'marketMD' ||
                role === 'singleMarketChampion') && (
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="country">{t('common-country')}</label>
                    <select
                      name="country"
                      id="country"
                      className="normal"
                      value={filters.country}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-country')}</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {t(`countries-${country}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              )}
              <Grid xs={12} sm={6} md={2}>
                <div className="form-control">
                  <label htmlFor="city">{t('common-city')}</label>
                  <select
                    name="city"
                    id="city"
                    className="normal"
                    value={filters.city}
                    onChange={handleFilterChange}
                  >
                    <option value="">{t('common-all-city')}</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={2}>
                <div className="actions">
                  <button className="btn" onClick={applyFilters}>
                    {t('common-apply')}
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="summary">
            <strong>
              {t('common-total-results')}:{' '}
              <span>{filteredRetailers.length}</span>
            </strong>
          </div>
          <div>
            {filteredRetailers.length !== 0 ? (
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="user-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('common-name')}</TableCell>
                    <TableCell align="center">{t('common-country')}</TableCell>
                    <TableCell align="center">{t('common-city')}</TableCell>
                    <TableCell align="center">
                      {t('common-number-sale-people')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRetailers.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="center">
                        {t(`countries-${row.area_code}`)}
                      </TableCell>
                      <TableCell align="center">{row.city}</TableCell>
                      <TableCell align="center">
                        {row.num_salespersons}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/admin/retailer-info/${row.id}`}
                          className="profile"
                        >
                          <span className="icon-chevron-right"></span>
                          <span className="text">
                            {t('common-retailer-details')}
                          </span>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="no-result">
                <h2 className="txt-center">{t('admin-main-title-empty')}</h2>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="no-result">
          <h2 className="txt-center">
            {t('admin-main-tab3-title-empty')}
            <small>{t('admin-retailer-tab-text-empty')}</small>
          </h2>
        </div>
      )}
    </>
  );
}

export default TableRetailer;
