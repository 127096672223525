import './preferences-hac.scss';
import React, { useState, useEffect } from 'react';
import { Collapse } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModalHacEditPreferences from '../modal-hac-edit-preferences/modal-hac-edit-preferences';
import ModalHacConfirmDeletePreferences from '../modal-hac-confirm-delete-preferences/modal-hac-confirm-delete-preferences';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';

export default function PreferencesHac({ cat }) {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const hacId = decodeToken(token).id;

  const [expandedItem, setExpandedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({
    title: '',
    value: '',
  });
  const [preferenceIdToDelete, setPreferenceIdToDelete] = useState(null);

  const [preferences, setPreferences] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const fetchData = async () => {
    setSelectedPreference({});
    try {
      const url = `${process.env.REACT_APP_API_URL}/hac/category-preferences/${hacId}?categoryId=${cat}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiClient.get(url, config);
      const data = response.data;
      setPreferences(data.length > 0 ? sortDataByTitle(data) : []);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  function sortDataByTitle(data) {
    return data.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  }

  useEffect(() => {
    fetchData();
  }, [hacId, cat, token]);

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const handleClick = (event, index, item) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
    setSelectedPreference(item);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedPreference({ title: '', value: '' });
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPreferenceIdToDelete(null);
  };

  const open = (index) => Boolean(anchorEl[index]);

  const handleDeletePreference = (preferenceId) => {
    //console.log('Deleting preference with ID:', preferenceId);
    setPreferenceIdToDelete(preferenceId);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <div className="actions">
        <button
          className="btn text"
          onClick={() => {
            setEditModalOpen(true);
          }}
        >
          {' '}
          <span className="icon-plus-circle"></span> {t('hac-preferences-add')}
        </button>
      </div>
      {preferences.length > 0 ? (
        <ul className="hac-preferences">
          {preferences.map((item, index) => (
            <li key={index}>
              <span>
                <span onClick={() => handleExpand(`preferences${index}`)}>
                  {expandedItem === `preferences${index}` ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}{' '}
                  {item.title}
                </span>
                <span
                  aria-controls={open(index) ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open(index) ? 'true' : undefined}
                  onClick={(event) => handleClick(event, index, item)}
                >
                  <span className="icon-more"></span>
                </span>
              </span>
              <Collapse in={expandedItem === `preferences${index}`}>
                <p>{item.value} </p>
              </Collapse>
              <Menu
                className="submenu"
                id={`menu${index}`}
                anchorEl={anchorEl[index]}
                open={open(index)}
                onClose={() => handleClose(index)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose(index);
                    setEditModalOpen(true);
                  }}
                >
                  <span className="icon-edit"></span>{' '}
                  {t('hac-preferences-menu1')}
                </MenuItem>
                <MenuItem onClick={() => handleDeletePreference(item.id)}>
                  <span className="icon-delete"></span>{' '}
                  {t('hac-preferences-menu2')}
                </MenuItem>
              </Menu>
            </li>
          ))}
        </ul>
      ) : (
        <h2>{t('hac-preferences-no-data')}</h2>
      )}

      <ModalHacEditPreferences
        className="confirm"
        open={editModalOpen}
        handleClose={handleEditModalClose}
        selectedPreference={selectedPreference}
        cat={cat}
        onSave={fetchData} // Pasa fetchData como onSave
      />
      <ModalHacConfirmDeletePreferences
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        preferenceId={preferenceIdToDelete}
        onDelete={fetchData} // Pasa fetchData como onDelete
      />
    </>
  );
}
