import './hac-vehicles.scss';
import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse, Snackbar } from '@mui/material';
import ModalPmaConfirmDeleteVehicle from '../modal-pma-confirm-delete-vehicle/modal-pma-confirm-delete-vehicle';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert } from '@mui/material';

function PmaEditHacVehicle() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;

  const { hacId } = useParams();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [expanded, setExpanded] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/pma/hac-vehicles/${hacId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiClient.get(url, config);
      setVehicles(response.data);
      console.log(vehicles);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteSuccess = () => {
    setSnackbarOpen(true); // Open Snackbar
    fetchData(); // Refresh vehicle data
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleMoreInfoClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleDeleteClick = (id) => {
    handleClose();
    setSelectedVehicleId(id);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <div className="vehicles">
        <div className="actions">
          <Link
            className="btn text"
            to="/pma/vehicles/new-vehicle"
            state={{ hacId: hacId }}
          >
            {' '}
            [+] {t('hac-my-profile-vehicles-add')}
          </Link>
        </div>

        {vehicles.length > 0 ? (
          vehicles.map((item, index) => (
            <div className="item" key={index}>
              <header>
                <h2>
                  {item.brand} {item.model}
                  <small>
                    {t('common-license-plate')}:{' '}
                    <strong>{item.license_plate}</strong>
                  </small>
                </h2>
                <span
                  className="btn text"
                  aria-controls={anchorEl[item.id] ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={
                    Boolean(anchorEl[item.id]) ? 'true' : undefined
                  }
                  onClick={handleClick(item.id)}
                >
                  <span className="icon-more"></span>
                </span>
                <Menu
                  className="submenu"
                  id={`${item.id}`}
                  anchorEl={anchorEl[item.id]}
                  open={Boolean(anchorEl[item.id])}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem>
                    <Link
                      to={`/pma/vehicles/edit-vehicle`}
                      state={{ vehicle: item, hacId: hacId }}
                    >
                      <span className="icon-edit"></span>{' '}
                      {t('hac-my-profile-vehicles-menu1')}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteClick(item.id)}>
                    <span className="icon-delete"></span>
                    {t('hac-my-profile-vehicles-menu2')}
                  </MenuItem>
                </Menu>
              </header>
              <Collapse in={expanded === index}>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={12} md={6}>
                    <ul>
                      <li>
                        <span>{t('common-vista-number')}</span>
                        <strong>{item.vista_number}</strong>
                      </li>
                      <li>
                        <span>{t('common-date-signature')}</span>
                        <strong>{item.signature_date}</strong>
                      </li>
                      <li>
                        <span>{t('common-vin')}</span>
                        <strong>{item.vin}</strong>
                      </li>
                      <li>
                        <span>{t('common-order-date')}</span>
                        <strong>{item.order_date}</strong>
                      </li>
                      <li>
                        <span>{t('common-registration-date')}</span>
                        <strong>{item.registration_date}</strong>
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <ul>
                      <li>
                        <span>{t('common-retailer-country')}</span>
                        {item.retailer_area_code ? (
                          <strong>
                            {t(`countries-${item.retailer_area_code}`)}
                          </strong>
                        ) : null}
                      </li>
                      <li>
                        <span>{t('common-retailer-city')}</span>
                        <strong>{item.retailer_city}</strong>
                      </li>
                      <li>
                        <span>{t('common-retailer-name')}</span>
                        <strong>{item.retailer_name}</strong>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Collapse>
              <div className="more-info">
                <button
                  className="btn text"
                  onClick={() => handleMoreInfoClick(index)}
                >
                  {t('hac-my-profile-vehicles-expand')}{' '}
                  {expanded === index ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}
                </button>
              </div>
            </div>
          ))
        ) : (
          <h2>{t('hac-preferences-no-data')}</h2>
        )}
      </div>
      <ModalPmaConfirmDeleteVehicle
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        vehicleId={selectedVehicleId}
        hacId={hacId}
        onSuccess={handleDeleteSuccess}
        fetchData={fetchData}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('feedback-save-vehicle-ok')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PmaEditHacVehicle;
