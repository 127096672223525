import './main.scss';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from "../../../../utils/decode-token";
import {useTranslation} from "react-i18next";


const MainCountryManager = () => {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('hac');
  const [reloadHac, setReloadHac] = useState(true);
  const [reloadPma, setReloadPma] = useState(true);
  const [reloadRetailer, setReloadRetailer] = useState(true);

  useEffect(() => {
    // Check if the URL pathname contains '/active' and set the activeTab accordingly
    if (location.pathname.includes('/hac')) {
      setActiveTab('hac');
    } else if (location.pathname.includes('/pma')) {
      setActiveTab('pma');
    } else if (location.pathname.includes('/retailer')) {
      setActiveTab('retailer');
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'hac') {
      setReloadHac(true);
      navigate('/country-manager/main/hac');
    } else if (tab === 'pma') {
      setReloadPma(true);
      navigate('/country-manager/main/pma');
    } else {
      setReloadRetailer(true);
      navigate('/country-manager/main/retailer');
    }
  };

  return (
    <>
      {
        (token && role === 'countryManager') ? (
          <div className="App">
            <Header/>
            <main>
              <div className="tabs">
                <ul>
                  <li className={activeTab === 'hac' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('hac')}>{t('admin-main-tabs-tab1')}</span>
                  </li>
                  <li className={activeTab === 'pma' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('pma')}>{t('admin-main-tabs-tab2')}</span>
                  </li>
                  <li className={activeTab === 'retailer' ? 'active' : ''}>
                    <span onClick={() => handleTabClick('retailer')}>{t('admin-main-tabs-tab3')}</span>
                  </li>
                </ul>
              </div>
              {activeTab === 'hac' && (
                <section className={`tab-content ${activeTab === 'hac' ? 'active' : ''}`}>
                  <div className="inner">
                    [Table hac]
                  </div>
                </section>
              )}
              {activeTab === 'pma' && (
                <section className={`tab-content ${activeTab === 'pma' ? 'active' : ''}`}>
                  <div className="inner">
                    [Table Pma]
                  </div>
                </section>
              )}
              {activeTab === 'retailer' && (
                <section className={`tab-content ${activeTab === 'retailer' ? 'active' : ''}`}>
                  <div className="inner">
                    [Table Retailer]
                  </div>
                </section>
              )}
            </main>
          </div>

        ) : (
          <Navigate to="/login"/>
        )
      }
    </>

  )
}

export default MainCountryManager;
