import React, { useState } from "react";

const FileUploader = ({ onFileUpload, loading }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadClick = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  return (
    <div className="file-input-wrapper">
      <input type="file" id="fileInput" onChange={handleFileChange} />
      <label htmlFor="fileInput" className="file-input-label">
        Choose File
      </label>
      {selectedFile && <span className="file-name">{selectedFile.name}</span>}
      <button onClick={handleUploadClick} disabled={loading || !selectedFile}>
        {loading ? "Uploading..." : "Upload"}
      </button>
    </div>
  );
};

export default FileUploader;
