import './modal-resend-nomination.scss';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Snackbar } from '@mui/material';
import { Box } from '@mui/material';
import apiClient from '../../../../utils/apiClient';
import dayjs from 'dayjs';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ModalResendNomination({ open, handleClose, hacData }) {
  const { t } = useTranslation();

  console.log(hacData);

  const token = sessionStorage.getItem('token') || null;

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const resentNomination = async () => {
    try {
      // Aquí se realiza la llamada al endpoint /api/v1/retailer/resend-invite
      const url = `${process.env.REACT_APP_API_URL}/retailer/resend-invite?hacId=${hacData.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await apiClient.post(url, {}, config);

      handleClose();
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error al enviar la invitación:', error);
      // Manejar errores si es necesario
    }
  };

  const getDaysAgo = (dateString) => {
    const nominationDate = dayjs(dateString, 'DD-MM-YYYY');
    const currentDate = dayjs();
    const daysAgo = currentDate.diff(nominationDate, 'day');
    return daysAgo;
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box-confirmation">
          <div className="box-content">
            <h2 id="modal-modal-title">{t('modal-are-you-sure')}</h2>
            <p id="modal-modal-description">{t('modal-send-invite') + ':'}</p>
            <p id="hac-name">
              {hacData.name} {hacData.last_name}
            </p>
            <p id="hac-email">
              {hacData ? hacData.email : 'email@example.com'}
            </p>
            <p className="two-columns">
              <span>
                {t('modal-nomination-number')}
                <strong>{hacData.send_count_invitation}</strong>
              </span>
              <span>
                {t('modal-last-nomination') + ':'}
                <strong>
                  {hacData ? getDaysAgo(hacData.nomination_date) : 0}
                  {t('common-days-ago')}
                </strong>
              </span>
            </p>
            <div className="modal-buttons">
              <Button
                variant="contained"
                className="btn button-mod cancel-mod"
                onClick={(event) => {
                  event.preventDefault();
                  handleClose(event);
                }}
              >
                {t('common-cancel')}
              </Button>
              <Button
                variant="contained"
                className="btn button-mod confirm-mod"
                type="submit"
                onClick={resentNomination}
              >
                {t('modal-confirm-nomination')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('feedback-nomination-resend-ok')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalResendNomination;
