import './nominate-hac.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Alert, Snackbar } from '@mui/material';

import { Link, Navigate } from 'react-router-dom';
import apiClient from '../../../../utils/apiClient';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ModalConfirmation from '../../../organisms/role-retailer/modal-confirmation/modal-confirmation.js';
import { useNavigate } from 'react-router-dom';
import Input from '../../../atoms/input.js';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function NominateHac() {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [open, setOpen] = useState(false);
  const [addressType, setAddressType] = useState('none'); // Added state for address type
  const [allowCheckbox, setAllowCheckbox] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const formRef = useRef();
  const navigate = useNavigate();

  const { formState, register, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  const { errors } = formState;

  // rellenar el select de pais con los paises disponibles
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/hac/countries`
        );
        setCountries(response.data);

        // Actualizar selectedCountry con el primer país de la lista
        if (response.data.length > 0) {
          setSelectedCountry(response.data[0].code);
        }
      } catch (error) {
        console.error('Error al obtener los países:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleOpen = async () => {
    try {
      if (!allowCheckbox) {
        setCheckboxError(true);
        return;
      } else {
        setCheckboxError(false);
      }

      await handleSubmit((formData) => {
        const schema = Joi.object({
          name: Joi.string().required(),
          last_name: Joi.string().required(),
          middle_name: Joi.string().allow('').optional(),
          email: Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
          prefix_phone: Joi.string().required(),
          phone: Joi.number().required(),
        });

        const fullPhoneNumber = parsePhoneNumberFromString(
          formData.prefix_phone + formData.phone
        );

        if (!fullPhoneNumber || !fullPhoneNumber.isValid()) {
          setPhoneError('Invalid phone number format');
          return;
        } else {
          setPhoneError('');
        }

        let commonSchema;

        if (addressType === 'private') {
          commonSchema = Joi.object({
            alias: Joi.string().required().label('Address Alias'),
            address_line_1: Joi.string().required().label('Street Address'),
            address_number: Joi.string().required().label('Address Number'),
            address_line_2: Joi.string()
              .allow('')
              .optional()
              .label('Address Line 2'),
            postal_code: Joi.string().required().label('Zip/Postal Code'),
            area_code: Joi.string().required().label('Country'),
            city: Joi.string().required().label('City'),
          });
        } else if (addressType === 'company') {
          commonSchema = Joi.object({
            company_name: Joi.string().required().label('Company Name'),
            address_line_1: Joi.string().required().label('Street Address'),
            address_number: Joi.string().required().label('Address Number'),
            address_line_2: Joi.string()
              .allow('')
              .optional()
              .label('Address Line 2'),
            postal_code: Joi.string().required().label('Zip/Postal Code'),
            area_code: Joi.string().required().label('Country'),
            city: Joi.string().required().label('City'),
          });
        } else {
          commonSchema = Joi.object({
            address_line_1: Joi.string().optional(),
            address_number: Joi.string().optional(),
            address_line_2: Joi.string().optional(),
            postal_code: Joi.string().optional(),
            area_code: Joi.string().optional(),
            city: Joi.string().optional(),
          });
        }

        if (addressType === 'none') {
          commonSchema = undefined;
        }

        if (commonSchema !== undefined) {
          const combinedSchema = commonSchema.concat(schema);

          const { error } = combinedSchema.validate(formData, {
            abortEarly: false,
          });

          if (error) {
            console.error(error);
            return;
          }
        }
        setOpen(true);
      })();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value);
  };

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  let urlConsent;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = handleSubmit(async () => {
    const {
      alias,
      company_name,
      address_line_1,
      address_number,
      address_line_2,
      postal_code,
      area_code,
      city,
      address_type,
      middle_name,
      ...rest
    } = Object.fromEntries(new FormData(formRef.current).entries());

    let formData = {
      ...rest,
    };

    if (address_type === 'private') {
      formData = {
        ...rest,
        address: [
          {
            alias: alias || ' ',
            address_line_1: address_line_1 || ' ',
            address_number: address_number || ' ',
            address_line_2: address_line_2 || ' ',
            postal_code: postal_code || ' ',
            area_code,
            city: city || ' ',
            address_type,
          },
        ],
      };
    } else if (address_type === 'company') {
      formData = {
        ...rest,
        address: [
          {
            company_name: company_name || ' ',
            address_line_1: address_line_1 || ' ',
            address_number: address_number || ' ',
            address_line_2: address_line_2 || ' ',
            postal_code: postal_code || ' ',
            area_code,
            city: city || ' ',
            address_type,
          },
        ],
      };
    }

    if (middle_name) {
      formData.middle_name = middle_name;
    }

    if (address_line_2) {
      formData.address[0].address_line_2 = address_line_2;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/hac/invite`,
        formData,
        config
      );
      if (response.status === 200) {
        navigate('/retailer/main');
        urlConsent = response.data.link;
      }
    } catch (error) {
      if (error.response.status === 409) {
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage("Can't create a duplicate HAC");
      } else {
        console.error('Error al enviar la invitación:', error);
      }
    }
  });

  return (
    <>
      {token && role === 'retailer' ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <form ref={formRef}>
                <Link to="/retailer/main/" className="back-button">
                  <span className="icon-chevron-left"></span> {t('common-back')}
                </Link>
                <h2>
                  {t('nominate-title')}
                  <small>{t('nominate-subtitle')}</small>
                </h2>

                <Grid container spacing={3}>
                  <Grid xs={4} sm={4} md={2}>
                    <div className="form-control">
                      <label htmlFor="title">{t('common-title')}</label>
                      <select name="title" id="title" className="normal">
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Mx">Mx</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={8} md={5}>
                    <div className="form-control required">
                      <Input
                        maxLength="50"
                        type="text"
                        id="name"
                        label={t('common-name')}
                        error={errors?.name}
                        {...register('name', {
                          validate: (v) => {
                            const { error } = Joi.string()
                              .required()
                              .error(() => new Error("Name can't be empty"))
                              .validate(v);
                            return error?.message ? error.message : true;
                          },
                        })}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={6} md={5}>
                    <div className="form-control">
                      <label htmlFor="middle_mame">
                        {t('common-middle-name')}
                      </label>
                      <Input
                        maxLength="50"
                        type="text"
                        id="middle_mame"
                        {...register('middle_name', {})}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6} md={5}>
                    <div className="form-control required">
                      <Input
                        maxLength="50"
                        type="text"
                        id="last_name"
                        label={t('common-last-name')}
                        error={errors?.last_name}
                        {...register('last_name', {
                          validate: (v) => {
                            const { error } = Joi.string()
                              .required()
                              .error(
                                () => new Error("Last Name can't be empty")
                              )
                              .validate(v);
                            return error?.message ? error.message : true;
                          },
                        })}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={1} md={6}>
                    <div className="form-control required">
                      <Input
                        maxLength="50"
                        type="text"
                        id="email"
                        label={t('common-email')}
                        error={errors?.email}
                        {...register('email', {
                          validate: (v) => {
                            const { error } = Joi.string()
                              .email({ tlds: { allow: false } })
                              .required()
                              .error(() => new Error("Email can't be empty"))
                              .validate(v);
                            return error?.message ? error.message : true;
                          },
                        })}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6} md={6}>
                    <div className="form-control required">
                      <Grid container spacing={1}>
                        <Grid xs={4} sm={4} md={4}>
                          <div className="form-control">
                            <Input
                              type="text"
                              id="prefix_phone"
                              label={t('common-prefix')}
                              error={errors?.prefix_phone}
                              {...register('prefix_phone', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .regex(/^\+[0-9]{1,3}$/)
                                    .required()
                                    .error(
                                      () =>
                                        new Error(
                                          "Prefix can't be empty or higher than +XXX"
                                        )
                                    )
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />
                          </div>
                        </Grid>
                        <Grid xs={8} sm={8} md={8}>
                          <div className="form-control required">
                            <Input
                              type="text"
                              id="phone"
                              label={t('common-phone')}
                              error={errors?.phone}
                              {...register('phone', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .required()
                                    .error(
                                      () => new Error("Phone can't be empty")
                                    )
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />
                            {phoneError && (
                              <p className="error-message">{phoneError}</p>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div className="form-control">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allowCheckbox}
                        onChange={(e) => {
                          setAllowCheckbox(e.target.checked);
                          setCheckboxError(!e.target.checked);
                        }}
                        style={{
                          borderColor: checkboxError ? 'red' : '',
                          color: checkboxError ? 'red' : '',
                        }}
                      />
                    }
                    label={
                      <span style={{ color: checkboxError ? 'red' : '' }}>
                        {t('hac-consents')}
                      </span>
                    }
                  />
                </div>
                <p>{t('retailer-nomination-choose-address')}</p>
                <div className="form-control">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleAddressTypeChange}
                    defaultValue={'none'}
                  >
                    <FormControlLabel
                      value="none"
                      name="address_type"
                      control={<Radio />}
                      label="None"
                    />
                    <FormControlLabel
                      value="private"
                      name="address_type"
                      control={<Radio />}
                      label={t('common-private-address')}
                    />
                    <FormControlLabel
                      value="company"
                      name="address_type"
                      control={<Radio />}
                      label={t('common-company-address')}
                    />
                  </RadioGroup>
                </div>

                {addressType === 'private' && (
                  <div>
                    <div className="form-control">
                      <Input
                        maxLength="50"
                        type="text"
                        id="alias_private"
                        placeholder="E.g. 'Second residence' or 'Holiday house'"
                        label={t('common-alias')}
                        error={errors?.alias}
                        {...register('alias', {
                          validate: (v) => {
                            const { error } = Joi.string()
                              .required()
                              .error(
                                () =>
                                  new Error('Alias is not allowed to be empty')
                              )
                              .validate(v);
                            return error?.message ? error.message : true;
                          },
                        })}
                      />
                    </div>
                    <Grid xs={12} sm={6} md={6}>
                      <div className="form-control">
                        <Grid container spacing={1}>
                          <Grid xs={8} sm={8} md={8}>
                            <div className="form-control">
                              <Input
                                maxLength="150"
                                type="text"
                                id="address_line_1_private"
                                label={t('common-address-name')}
                                error={errors?.address_line_1}
                                {...register('address_line_1', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .required()
                                      .error(
                                        () =>
                                          new Error(
                                            'Street Address is not allowed to be empty'
                                          )
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                          <Grid xs={4} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="5"
                                type="text"
                                id="address_number_private"
                                label={t('common-address-number')}
                                error={errors?.address_number}
                                {...register('address_number', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .required()
                                      .error(
                                        () =>
                                          new Error(
                                            'Address Number is not allowed to be empty'
                                          )
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <div className="form-control">
                      <label htmlFor="address_line_2">
                        {t('common-address-line-2')}
                      </label>
                      <input
                        maxLength="150"
                        type="text"
                        id="address_line_2_private"
                        {...register('address_line_2')}
                      />
                    </div>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={1} md={4}>
                        <div className="form-control">
                          <label htmlFor="country">{t('common-country')}</label>
                          <select
                            name="area_code_private"
                            id="country_private"
                            className="normal"
                            error={errors?.area_code}
                            {...register('area_code', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(() => new Error('Country is required'))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          >
                            <option key="" value="">
                              {t('common-select-country')}
                            </option>
                            {countries.map((country) => (
                              <option
                                key={country.area_code}
                                value={country.area_code}
                              >
                                {t('countries-' + country.area_code)}
                              </option>
                            ))}
                          </select>
                          {errors?.area_code && (
                            <span className="input__error">
                              {errors.area_code.message}
                            </span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} sm={4} md={4}>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="city_private"
                            label={t('common-city')}
                            error={errors?.city}
                            {...register('city', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(
                                    () =>
                                      new Error(
                                        'City is not allowed to be empty'
                                      )
                                  )
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={4} md={4}>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="postal_code_private"
                            label={t('common-zip-cp')}
                            error={errors?.postal_code}
                            {...register('postal_code', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(
                                    () =>
                                      new Error(
                                        'Postal Code is not allowed to be empty'
                                      )
                                  )
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {addressType === 'company' && (
                  <div>
                    <div className="form-control">
                      <Input
                        maxLength="50"
                        type="text"
                        id="company_name"
                        label={t('common-company-name')}
                        error={errors?.company_name}
                        {...register('company_name', {
                          validate: (v) => {
                            const { error } = Joi.string()
                              .required()
                              .error(
                                () =>
                                  new Error(
                                    'Company Name is not allowed to be empty'
                                  )
                              )
                              .validate(v);
                            return error?.message ? error.message : true;
                          },
                        })}
                      />
                    </div>
                    <Grid xs={12} sm={6} md={6}>
                      <div className="address_new">
                        <Grid container spacing={1}>
                          <Grid xs={8} sm={8} md={8}>
                            <div className="form-control">
                              <Input
                                maxLength="150"
                                type="text"
                                id="address_line_1_company"
                                label={t('common-address-name')}
                                error={errors?.address_line_1}
                                {...register('address_line_1', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .required()
                                      .error(
                                        () =>
                                          new Error(
                                            'Street Address is not allowed to be empty'
                                          )
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                          <Grid xs={4} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="5"
                                type="text"
                                id="address_number_company"
                                label={t('common-address-number')}
                                error={errors?.address_number}
                                {...register('address_number', {
                                  validate: (v) => {
                                    const { error } = Joi.string()
                                      .required()
                                      .error(
                                        () =>
                                          new Error(
                                            'Address Number is not allowed to be empty'
                                          )
                                      )
                                      .validate(v);
                                    return error?.message
                                      ? error.message
                                      : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <div className="form-control">
                      <label htmlFor="address_line_2_company">
                        {t('common-address-line-2')}
                      </label>
                      <input
                        maxLength="150"
                        type="text"
                        id="address_line_2_company"
                        {...register('address_line_2')}
                      />
                    </div>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={1} md={4}>
                        <div className="form-control">
                          <label htmlFor="country_company">
                            {t('common-country')}
                          </label>
                          <select
                            name="area_code_company"
                            id="country_company"
                            className="normal"
                            error={errors?.area_code}
                            {...register('area_code', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(() => new Error('Country is required'))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          >
                            <option key="" value="">
                              {t('common-select-country')}
                            </option>
                            {countries.map((country) => (
                              <option
                                key={country.area_code}
                                value={country.area_code}
                              >
                                {t('countries-' + country.area_code)}
                              </option>
                            ))}
                          </select>
                          {errors?.area_code && (
                            <span className="input__error">
                              {errors.area_code.message}
                            </span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} sm={4} md={4}>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="city_company"
                            label={t('common-city')}
                            error={errors?.city}
                            {...register('city', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(
                                    () =>
                                      new Error(
                                        'City is not allowed to be empty'
                                      )
                                  )
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={4} md={4}>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="postal_code_company"
                            label={t('common-zip-cp')}
                            error={errors?.postal_code}
                            {...register('postal_code', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(
                                    () =>
                                      new Error(
                                        'Postal Code is not allowed to be empty'
                                      )
                                  )
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}

                <Grid container spacing={3}>
                  <Grid xs={12} sm={6} md={6}>
                    <button className="btn" type="button" onClick={handleOpen}>
                      {t('retailer-nomination-action')}
                    </button>
                  </Grid>
                </Grid>
              </form>
              {open ? (
                <ModalConfirmation
                  open={open}
                  handleClose={handleClose}
                  onSubmit={onSubmit}
                  formRef={formRef}
                />
              ) : null}
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default NominateHac;
