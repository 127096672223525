import React from "react";
import "./input.scss";
import clsx from "clsx";

function Input({ label, error, className, ...props }, ref) {
  return (
    <>
      <label
        htmlFor="last_name"
        className={clsx("input_label", { input_label__error: error })}
      >
        {label}
      </label>
      <input
        maxLength="50"
        ref={ref}
        {...props}
        className={clsx(className, { error: error })}
      />
      {error && <p className="input__error">{error.message}</p>}
    </>
  );
}
Input.displayName = "Input";

export default React.forwardRef(Input);
