import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import apiClient from '../../../../utils/apiClient';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TableHacActive({ reload, onDataLoaded }) {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const token = sessionStorage.getItem('token') || null;

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/hac/retailer-salesperson?status=active`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setRows(response.data);
          onDataLoaded(); // Notificar a MainRetailer que los datos se han cargado
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      } finally {
        setDataLoaded(true); // Marcar los datos como cargados
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded]);

  return (
    <>
      {dataLoaded ? (
        rows && rows.length !== 0 ? (
          <div>
            <div className="intro">
              <div className="text">
                <p>
                  <strong>{t('retailer-main-active-title')}</strong>
                </p>
                <p>{t('retailer-main-active-text')}</p>
              </div>
              <div className="actions">
                <Link to="/retailer/nominate-hac" className="btn">
                  + {t('retailer-main-active-action')}
                </Link>
              </div>
            </div>
            <div className="table-wrapper">
              <Table aria-label="simple table" className="user-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('common-name')}</TableCell>
                    <TableCell align="left">{t('common-last-name')}</TableCell>
                    <TableCell align="center">{t('common-phone')}</TableCell>
                    <TableCell align="center">{t('common-pma')}</TableCell>
                    <TableCell align="center">{t('common-status')}</TableCell>
                    <TableCell align="center">
                      {t('common-last-update')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.last_name}</TableCell>
                      <TableCell align="center">
                        {row.prefix_phone} {row.phone}
                      </TableCell>
                      <TableCell align="center">{row.pma} </TableCell>
                      <TableCell align="center">
                        <span
                          className={
                            row.login_status === 'ready' ? 'logged' : ''
                          }
                        >
                          {row.login_status === 'ready'
                            ? t('common-logged')
                            : t('common-not-logged')}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.last_update}</TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/retailer/hac-profile/${row.id}`}
                          className="profile"
                        >
                          <span className="icon-chevron-right"></span>
                          <span className="text">{t('common-profile')}</span>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('retailer-main-active-empty-title')}
              <small>{t('retailer-main-active-empty-text')}</small>
            </h2>
            <Link to="/retailer/nominate-hac" className="btn">
              <span className="icon-plus"></span>{' '}
              {t('retailer-main-active-action')}
            </Link>
          </div>
        )
      ) : null}
    </>
  );
}

export default TableHacActive;
