import './preferences-hac.scss';
import React, { useState, useEffect } from 'react';
import { Collapse } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModalPmaEditPreferences from '../modal-hac-edit-preferences/modal-hac-edit-preferences';
import ModalPmaConfirmDeletePreferences from '../modal-hac-confirm-delete-preferences/modal-hac-confirm-delete-preferences';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { useParams } from 'react-router-dom';

export default function PmaPreferencesHac() {
  const { t } = useTranslation();
  const { hacId } = useParams();
  const token = sessionStorage.getItem('token') || null;

  const [expandedItem, setExpandedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({});
  const [preferenceIdToDelete, setPreferenceIdToDelete] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const fetchData = async () => {
    setSelectedPreference({});
    try {
      const url = `${process.env.REACT_APP_API_URL}/pma/hac-preferences/${hacId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiClient.get(url, config);
      setPreferences(sortDataByCategoryAndTitle(response.data));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  function sortDataByCategoryAndTitle(data) {
    return data.sort((a, b) => {
      const categoryA = a.category_id.toLowerCase();
      const categoryB = b.category_id.toLowerCase();
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  }

  useEffect(() => {
    fetchData();
  }, [hacId, token]);

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const handleClick = (event, index, item) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
    setSelectedPreference(item);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedPreference({ title: '', value: '' });
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPreferenceIdToDelete(null);
  };

  const open = (index) => Boolean(anchorEl[index]);

  const handleDeletePreference = (preferenceId) => {
    setPreferenceIdToDelete(preferenceId);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <div className="actions">
        <button className="btn text" onClick={() => setEditModalOpen(true)}>
          <span className="icon-plus-circle"></span> {t('pma-preferences-add')}
        </button>
      </div>
      {Array.isArray(preferences) ? (
        <ul className="hac-preferences">
          {preferences.map((item, index) => (
            <li key={index}>
              <span>
                <span
                  className="header"
                  onClick={() => handleExpand(`preferences${index}`)}
                >
                  {expandedItem === `preferences${index}` ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}
                  <span>{t(`hac-preferences-${item.category_id}`)}</span>
                  <span>{item.title}</span>
                </span>
                <span
                  aria-controls={open(index) ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open(index) ? 'true' : undefined}
                  onClick={(event) => handleClick(event, index, item)}
                >
                  <span className="icon-more"></span>
                </span>
              </span>
              <Collapse in={expandedItem === `preferences${index}`}>
                <p>{item.value}</p>
              </Collapse>
              <Menu
                className="submenu"
                id={`menu${index}`}
                anchorEl={anchorEl[index]}
                open={open(index)}
                onClose={() => handleClose(index)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose(index);
                    setEditModalOpen(true);
                  }}
                >
                  <span className="icon-edit"></span>{' '}
                  {t('hac-preferences-menu1')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeletePreference(item.id);
                    handleClose(index);
                  }}
                >
                  <span className="icon-delete"></span>{' '}
                  {t('hac-preferences-menu2')}
                </MenuItem>
              </Menu>
            </li>
          ))}
        </ul>
      ) : (
        <h2>{t('hac-preferences-no-data')}</h2>
      )}

      <ModalPmaEditPreferences
        className="confirm"
        open={editModalOpen}
        handleClose={handleEditModalClose}
        selectedPreference={selectedPreference}
        hacId={hacId}
        onSave={fetchData} // Pasa fetchData como onSave
      />
      <ModalPmaConfirmDeletePreferences
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        preferenceId={preferenceIdToDelete}
        hacId={hacId}
        onDelete={fetchData} // Pasa fetchData como onDelete
      />
    </>
  );
}
