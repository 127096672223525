import axios from 'axios';
import decodeToken from './decode-token';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshToken = async (refreshTokenValue) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshTokenValue}`,
        },
      }
    );
    sessionStorage.setItem('token', response.data.token);
    sessionStorage.setItem('refresh_token', response.data.refresh_token);
    return response.data.token;
  } catch (error) {
    console.error('Session expired. Please log in again.');
    throw error;
  }
};

apiClient.interceptors.request.use(
  async (config) => {
    let token = sessionStorage.getItem('token');
    const refreshTokenValue = sessionStorage.getItem('refresh_token');

    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;

        if (timeUntilExpiration < 60 * 1000 && refreshTokenValue) {
          token = await refreshToken(refreshTokenValue);
        }
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh_token');
      //  window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default apiClient;
