import React from 'react';
import Modal from '@mui/material/Modal';
import {Box} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function ModalDataSaved({ open, handleClose, handleAceptModalDataSaved }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-modal-title">Data Saved</h2>
        <p id="modal-modal-description">Your data has been successfully saved.</p>
        <button className="btn" onClick={() => { handleAceptModalDataSaved(); }}>Aceptar</button>
      </Box>
    </Modal>
  );
}

export default ModalDataSaved;