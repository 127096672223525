import './main.scss';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../../../organisms/header/header';
import decodeToken from '../../../../utils/decode-token';
import { saveAs } from 'file-saver';
import apiClient from '../../../../utils/apiClient';

function MainShippingAdmin() {
  const [fileDownloaded, setFileDownloaded] = useState(false);

  const handleDownloadFile = async () => {
    try {
      const authToken = `Bearer ${sessionStorage.getItem('token')}`;
      const url = `${process.env.REACT_APP_API_URL}/admin/new-address-file`;

      const response = await apiClient.get(url, {
        headers: { Authorization: authToken },
        responseType: 'blob',
      });

      saveAs(response.data, 'addresses.xlsx');
      setFileDownloaded(true);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const token = sessionStorage.getItem('token') || null;
  const role = token ? decodeToken(token).role : null;

  return (
    <>
      {token && role === 'shippingAdmin' ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <div className="inner">
                <h2>descarga fichero de direcciones</h2>
                <p>Excel con las direcciones</p>
                <div className="actions">
                  <button className="btn" onClick={handleDownloadFile}>
                    Download Excel File
                  </button>
                  {fileDownloaded && (
                    <p className="downloaded">File downloaded successfully!</p>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default MainShippingAdmin;
