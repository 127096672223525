import './profile.scss';
import Header from '../../../organisms/header/header.js'
import React from "react";
import {Link, Navigate} from "react-router-dom";
import HacVehicles from "../../../organisms/role-hac/hac-vehicles/hac-vehicles";
import Wadsworth from "../../../organisms/role-hac/wadsworth/wadsworth";
import {useTranslation} from "react-i18next";
import decodeToken from "../../../../utils/decode-token";

function Vehicles() {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || '';
  const role = decodeToken(token).role;

  return (
    <>
      {
        (token && role === 'hac') ? (
          <div className="App">
            <Header/>
            <main>
              <Wadsworth/>
              <section>
                <div className="inner menu">
                  <aside className="lateral-menu">
                    <h2>{t('hac-menu-profile-title')}</h2>
                    <ul>
                      <li><Link to="/hac/profile/basic-info">{t('hac-menu-profile-menu1')}</Link></li>
                      <li className="on"><Link to="/hac/profile/vehicles">{t('hac-menu-profile-menu2')}</Link></li>
                    </ul>
                  </aside>
                  <main>
                    <h4>{t('hac-my-profile-vehicles-title')}</h4>
                    <p>{t('hac-my-profile-vehicles-text')}</p>
                    <HacVehicles/>
                  </main>
                </div>
              </section>
            </main>
          </div>

        ) : (
          <Navigate to="/login" />
        )
      }
    </>

  )
}

export default Vehicles;