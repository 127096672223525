import './main.scss';
import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import Header from '../../../organisms/header/header.js'
import decodeToken from "../../../../utils/decode-token";
import Wadsworth from "../../../organisms/role-hac/wadsworth/wadsworth";
import {useTranslation} from "react-i18next";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Gallery1 from '../../../../assets/img/gallery1.jpg';
import Gallery2 from '../../../../assets/img/gallery2.jpg';
import Gallery3 from '../../../../assets/img/gallery3.jpg';
import Gallery4 from '../../../../assets/img/gallery4.jpg';

const MainHac = () => {

  //console.log("main hac");
  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  /* Ancho de pantalla */
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    // Agregar un listener para el evento de cambio de tamaño de pantalla
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* Slick slider react */
  const [sliderNav, setNav] = useState();
  const [sliderContent, setContent] = useState();

  const settingsNav = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 4,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content'
        }
      },
      {
        // breakpoint para el slider de beneftis cambiar tambien en variables.scss buscando el nombre de esta variable ($custom-slider-beneficts)
        breakpoint: 1210,

        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 1,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content'
        }
      }
    ]
  }
  const settingsContent = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav'
        }
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav'
        }
      }
    ]
  }

  return (
    <>
      {
        (token && role === 'hac') ? (
          <div className="App">
            <Header/>
            <main className="landing">
              <Wadsworth/>
              <section className="hero">
                <div>
                  <h1>{t('hac-landing-hero-title')}<small>{t('hac-landing-hero-subtitle')}</small></h1>
                </div>
              </section>

              <section className="intro">
                <div className="inner">
                  <h2>{t('hac-landing-intro-title')}</h2>
                  <p>{t('hac-landing-intro-text')}</p>
                </div>
              </section>

              <section className="benefits dark">
                <div className="inner">
                  <h2>{t('hac-landing-benefits-title')}</h2>
                  <p>{t('hac-landing-benefits-text')}</p>
                  <div className="img">

                  </div>

                  <Slider className="benefits-nav" {...settingsNav} asNavFor={sliderContent}
                          ref={(slider1) => setNav(slider1)}>
                    <div>{t('hac-landing-benefits-tab1-tab')}</div>
                    <div>{t('hac-landing-benefits-tab2-tab')}</div>
                    <div>{t('hac-landing-benefits-tab3-tab')}</div>
                    <div>{t('hac-landing-benefits-tab4-tab')}</div>
                  </Slider>
                  <Slider className="benefits-content" {...settingsContent} asNavFor={sliderNav}
                          ref={(slider2) => setContent(slider2)}>

                    <div className="content">
                      <h4>{t('hac-landing-benefits-tab1-title')}</h4>
                      <div className="text">
                        {/*<p><strong>{t('hac-landing-benefits-tab1-subTitle')}</strong></p>*/}
                        <p><span>{t('hac-landing-benefits-tab1-text')}</span> </p>
                      </div>
                    </div>

                    <div className="content">
                      <h4>{t('hac-landing-benefits-tab2-title')}</h4>
                      <div className="text">
                        {/*<p><strong>{t('hac-landing-benefits-tab2-subTitle')}</strong></p>*/}
                        <p><span>{t('hac-landing-benefits-tab2-text')}</span> </p>
                      </div>
                    </div>

                    <div className="content">
                      <h4>{t('hac-landing-benefits-tab3-title')}</h4>
                      <div className="text">
                        {/*<p><strong>{t('hac-landing-benefits-tab3-subTitle')}</strong></p>*/}
                        <p><span>{t('hac-landing-benefits-tab3-text')}</span> </p>
                      </div>
                    </div>

                    <div className="content">
                      <h4>{t('hac-landing-benefits-tab4-title')}</h4>
                      <div className="text">
                        <p><strong>{t('hac-landing-benefits-tab4-subTitle')}</strong></p>
                        <p><span>{t('hac-landing-benefits-tab4-text')}</span></p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </section>

              <section className="gallery">
                <div className="inner">
                  <h2>{t('hac-landing-gallery-title')}</h2>
                  <p>{t('hac-landing-gallery-text')}</p>
                  <h3>{t('hac-landing-gallery-subTitle')}</h3>
                  <ul>
                    <li>
                      <img src={Gallery1} alt=""/>
                      <p><strong>{t('hac-landing-gallery-title-item1')}</strong>{t('hac-landing-gallery-text-item1')}</p>
                    </li>
                    <li>
                      <img src={Gallery2} alt=""/>
                      <p><strong>{t('hac-landing-gallery-title-item2')}</strong>{t('hac-landing-gallery-text-item2')}</p>
                    </li>
                    <li>
                      <img src={Gallery3} alt=""/>
                      <p><strong>{t('hac-landing-gallery-title-item3')}</strong>{t('hac-landing-gallery-text-item3')}</p>
                    </li>
                  </ul>
                </div>

              </section>


            </main>
          </div>

        ) : (
          <Navigate to="/login"/>
        )
      }
    </>
  )
}

export default MainHac;