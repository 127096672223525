import './test.scss';
import Header from '../../organisms/header/header.js'

function Test() {

  return (
    <>
      <div className="App">
        <Header/>
        <main>
          Aqui lo que quieras añadir
        </main>
      </div>
    </>
  )
}

export default Test;