import './header-menu.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../utils/apiClient';

function HeaderMenu() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [last_name, setLastName] = useState('');

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  const userRole = decodedToken.role;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }
        let url = '';
        if (userRole === 'retailer') {
          url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${userId}`;
        }
        if (userRole === 'pma') {
          url = `${process.env.REACT_APP_API_URL}/pma`;
        }
        if (
          userRole === 'admin' ||
          userRole === 'marketAdmin' ||
          userRole === 'countryAdmin'
        ) {
          url = `${process.env.REACT_APP_API_URL}/admin/${userId}`;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        if (userRole !== 'superAdmin') {
          const response = await apiClient.get(url, config);
          setName(response.data.name);
          setLastName(response.data.last_name);

          const userLanguage = response.data.preferred_language || 'en';
          i18n.changeLanguage(userLanguage.toLowerCase());
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
    navigate('/login/logout');
  };

  const handleHome = () => {
    if (
      userRole === 'admin' ||
      userRole === 'marketAdmin' ||
      userRole === 'countryAdmin'
    ) {
      navigate(`/admin/main/`);
    } else {
      navigate(`/${userRole}/main/`);
    }
  };
  const handleSettings = () => {
    if (!token) {
      console.error('No se encontró ningún token en sessionStorage.');
      return;
    }
    //console.log(userRole)
    try {
      switch (userRole) {
        case 'retailer':
          navigate('/retailer/settings/');
          break;
        case 'pma':
          navigate('/pma/settings/');
          break;
        case 'superAdmin':
          navigate('/super-admin/settings/');
          break;
        case 'admin':
          navigate('/admin/settings/');
          break;
        case 'marketAdmin':
          navigate('/admin/settings/');
          break;
        case 'countryAdmin':
          navigate('/admin/settings/');
          break;
        case 'shippingAdmin':
          navigate('/admin/settings/');
          break;
        default:
          console.error('Rol de usuario no reconocido:', userRole);
      }
    } catch (error) {
      console.error('Error al decodificar el token:', error);
    }
  };

  const DropDownMenu = () => {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button
              variant="contained"
              {...bindTrigger(popupState)}
              className="dropdown"
            >
              <span className="data">
                {name} {last_name}
              </span>{' '}
              <span className="icon-user"></span>
            </Button>
            <Menu className="dropdown-menu" {...bindMenu(popupState)}>
              <MenuItem onClick={handleHome}>{t('common-home')}</MenuItem>
              <MenuItem onClick={handleSettings}>
                {t('common-settings')}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t('common-logout')}</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  return (
    <div>
      <DropDownMenu />
    </div>
  );
}

export default HeaderMenu;
