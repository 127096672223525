import './modal-session.scss';
import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalSessionExpired({ open, handleClose }) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2>{t('expired-title')}</h2>
        <p>{t('expired-text')}</p>
        <div className="actions">
          <Link onClick={handleClose} className="btn">
            {t('common-login')}
          </Link>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalSessionExpired;
