import './modal-edit-saleperson.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from "react-i18next";
import apiClient from "../../../../utils/apiClient";
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from 'react-hook-form';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalEditSaleperson({ open, onClose, currentSalesPerson }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token") || null;
  console.log(currentSalesPerson);
  let salePersonId = '';
  if (currentSalesPerson) {
    salePersonId = currentSalesPerson.id;
  }

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (currentSalesPerson) {
      setValue('name', currentSalesPerson.name || '');
      setValue('last_name', currentSalesPerson.last_name || '');
      setValue('email', currentSalesPerson.email || '');
      setValue('prefix_phone', currentSalesPerson.prefix_phone || '');
      setValue('phone', currentSalesPerson.phone || '');
    }
  }, [currentSalesPerson, setValue]);

  const onSubmit = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/personal-settings?salePersonId=${salePersonId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const { email, ...updatedData } = data; // Excluir el campo email

    try {
      const response = await apiClient.patch(url, updatedData, config);
      if (response.status === 200) {
        setSnackbarOpen(true);
        onClose(); // Cerrar la modal al guardar
      }
    } catch (error) {
      console.error('Error al guardar la información del salesperson:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="edit-saleperson-modal">
        <Box sx={style}>
          <h2>{t('edit-salesperson-title')}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6}>
                <div className={`form-control required ${
                  errors.name ? 'error' : ''
                }`}>
                  <label>{t('common-name')}</label>
                  <input
                    type="text"
                    name="name"
                    {...register('name', { required: "Name is required" })}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className={`form-control required ${
                  errors.last_name ? 'error' : ''
                }`}>
                  <label>{t('common-last-name')}</label>
                  <input
                    type="text"
                    name="last_name"
                    {...register('last_name', { required: "Last name is required" })}
                  />
                  {errors.last_name && <span>{errors.last_name.message}</span>}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={12}>
                <div className="form-control">
                  <label>{t('common-email')}</label>
                  <input
                    type="email"
                    name="email"
                    {...register('email')}
                    readOnly
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={3}>
                <div className={`form-control required ${
                  errors.prefix_phone ? 'error' : ''
                }`}>
                  <label>{t('common-prefix')}</label>
                  <input
                    type="text"
                    name="prefix_phone"
                    {...register('prefix_phone', {
                      required: "Prefix is required",
                      pattern: {
                        value: /^\+[0-9]{2}$/,
                        message: 'Prefix format +xx',
                      },
                    })}
                  />
                  {errors.prefix_phone && <span>{errors.prefix_phone.message}</span>}
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={9}>
                <div className={`form-control required ${
                  errors.phone ? 'error' : ''
                }`}>
                  <label>{t('common-phone')}</label>
                  <input
                    type="text"
                    name="phone"
                    {...register('phone', {
                      required: "Phone is required",
                      pattern: {
                        value: /^\d{9}$/, // Expresión regular para nueve números
                        message: 'Phone must be 9 digits long',
                      },
                    })}
                  />
                  {errors.phone && <span>{errors.phone.message}</span>}
                </div>
              </Grid>
            </Grid>

            <div className="actions">
              <button className="btn secondary" onClick={onClose}>{t('common-cancel')}</button>
              <button className="btn" type="submit">{t('common-save')}</button>
            </div>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {t('guardado-correctamente')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalEditSaleperson;
