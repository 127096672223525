import './modal-nominate-on-behalf.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import apiClient from '../../../../utils/apiClient';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalNominateOnBehalf({ open, onClose }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const navigate = useNavigate();

  const [retailerAreaCode, setRetailerAreaCode] = useState('');
  const [retailerCity, setRetailerCity] = useState('');
  const [retailerId, setRetailerId] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [salespersons, setSalespersons] = useState([]);
  const [salespersonId, setSalespersonId] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/admin/retailers`,
          config
        );
        setRetailers(response.data);

        const uniqueCountries = Array.from(
          new Set(response.data.map((retailer) => retailer.area_code))
        );
        setUniqueCountries(uniqueCountries);
      } catch (error) {
        console.error('Error fetching retailers:', error);
      }
    };

    fetchRetailers();
  }, [token]);

  useEffect(() => {
    let filtered = retailers;
    if (retailerAreaCode) {
      filtered = filtered.filter(
        (retailer) => retailer.area_code === retailerAreaCode
      );
    }
    if (citySearch) {
      filtered = filtered.filter((retailer) =>
        retailer.city.toLowerCase().includes(citySearch.toLowerCase())
      );
    }
    setFilteredRetailers(filtered);
  }, [retailerAreaCode, citySearch, retailers]);

  const handleRetailerChange = async (e) => {
    const selectedRetailerId = e.target.value;
    setRetailerId(selectedRetailerId);
    setSalespersons([]);
    setErrorMessage(false);
    const selectedRetailer = retailers.find(
      (retailer) => retailer.id === parseInt(selectedRetailerId)
    );
    if (selectedRetailer) {
      setRetailerAreaCode(selectedRetailer.area_code);
      setRetailerCity(selectedRetailer.city);

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson?retailerId=${selectedRetailerId}`,
          config
        );
        if (response.data.length === 0) {
          setErrorMessage(true);
        } else {
          setSalespersons(response.data);
          setErrorMessage(false);
        }
      } catch (error) {
        console.error('Error fetching salespersons:', error);
        setErrorMessage(true);
      }
    }
  };

  const handleCitySearchChange = (e) => {
    setCitySearch(e.target.value);
    setRetailerCity(e.target.value);
  };

  const handleSalespersonChange = (e) => {
    setSalespersonId(e.target.value);
  };

  const handleContinue = () => {
    if (salespersons.length === 0) {
      setErrorMessage(true);
    } else {
      navigate(`/admin/nominate?salesperson_id=${salespersonId}`);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="on-behalf">
      <Box sx={style}>
        <h2>{t('admin-nomination-title')}</h2>
        <p>{t('admin-nomination-text')}</p>
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={6}>
            <div className="form-control">
              <label htmlFor="retailer_area_code">{t('common-country')}</label>
              <select
                name="retailer_area_code"
                id="retailer_area_code"
                value={retailerAreaCode}
                onChange={(e) => setRetailerAreaCode(e.target.value)}
                className="normal"
              >
                <option value="">{t('common-select-country')}</option>
                {uniqueCountries.map((countryCode) => (
                  <option key={countryCode} value={countryCode}>
                    {t(`countries-${countryCode}`)}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <div className="form-control">
              <label htmlFor="retailer_city">{t('common-city')}</label>
              <input
                type="text"
                id="retailer_city"
                name="retailer_city"
                value={retailerCity}
                onChange={handleCitySearchChange}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <div className="form-control">
              <label htmlFor="retailer_name">{t('common-retailer-name')}</label>
              <select
                name="retailer_name"
                id="retailer_name"
                value={retailerId}
                onChange={handleRetailerChange}
                className="normal"
              >
                <option value="">{t('common-select-retailer')}</option>
                {filteredRetailers.map((retailer) => (
                  <option key={retailer.id} value={retailer.id}>
                    {retailer.name}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          {retailerId && (
            <Grid xs={12} sm={12} md={12}>
              <div className="form-control">
                <label htmlFor="salesperson_name">
                  {t('common-saleperson')}
                </label>
                {salespersons.length > 0 ? (
                  <select
                    name="salesperson_name"
                    id="salesperson_name"
                    className="normal"
                    onChange={handleSalespersonChange}
                  >
                    <option value="">{t('common-select-saleperson')}</option>
                    {(Array.isArray(salespersons) ? salespersons : []).map(
                      (salesperson) => (
                        <option key={salesperson.id} value={salesperson.id}>
                          {`${salesperson.name} ${salesperson.last_name}`}
                        </option>
                      )
                    )}
                  </select>
                ) : (
                  <p className={errorMessage ? 'error' : ''}>
                    There is no salesperson associated
                  </p>
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <div className="actions">
          <button className="btn secondary" onClick={onClose}>
            {t('common-cancel')}
          </button>
          <button className="btn" onClick={handleContinue}>
            {t('common-continue')}
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalNominateOnBehalf;
